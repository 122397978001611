import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Получение информации об активности пользователя
 */
export const getUserActivity = gql`
  query getUserActivity($page: Int) {
    userActivity(page: $page)
      @rest(
        path: "/spa/profile/activity?{args}"
        type: "UserActivity"
        method: "GET"
      ) {
      data @type(name: "[UserActivity]")
      pagination
    }
  }
`;
