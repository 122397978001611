
import { Component, Mixins, Prop } from 'vue-property-decorator';

import FormChangePassword from '~/components/form-change-password';
import { UseEmailVerification } from '~/mixins';

/**
 * Модалка с формой подтверждения изменения почтового адреса
 */
@Component<ModalChangePassword>({
  components: { FormChangePassword },
})
export default class ModalChangePassword extends Mixins(
  UseEmailVerification,
) {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;

  /**
   * Отображение сообщения об успешной отправке
   */
  isShowMessage = false;
}
