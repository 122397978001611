/**
 * Копирование строки в буфер обмена
 *
 * @param string То что нужно скопировать
 * @param element Элемент внутри которого будет создан временный инпут.
 *   Применяется когда текст для копирования находится внутри модального окна
 */
export function copyStringToBuffer(
  string: string,
  element: Element = document.body,
): void {
  const temporaryInput = document.createElement('INPUT') as HTMLInputElement;
  const { activeElement }: any = document;

  temporaryInput.value = string;
  element.append(temporaryInput);
  temporaryInput.select();
  document.execCommand('copy');
  temporaryInput.remove();
  activeElement?.focus?.();
}
