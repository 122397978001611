import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Подтверждение удаления аккаунта (окончательно)
 *
 * @param token Токен
 */

export const acceptAccountDeletion = gql`
  mutation acceptAccountDeletion($token: string) {
    acceptAccountDeletion(token: $token)
      @rest(
        path: "/spa/settings/deleteAccount/{args.token}"
        type: "AcceptAccountDeletion"
        method: "GET"
      ) {
      status
      errors
      message
    }
  }
`;
