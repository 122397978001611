var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":_vm.$api.Settings.generate2faData,"tag":""},on:{"error":function (error) { return (_vm.errorText = _vm.getErrorText(error.message)); },"result":function (result) {
      if (!result.error) { _vm.$emit('done'); }
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var data = ref.result.data;
    var isLoading = ref.isLoading;
return [_c('v-expand-transition',{attrs:{"group":"","leave-absolute":""}},[(_vm.errorText)?_c('ui-alert',{key:"alert",staticClass:"[ mb-4 ]",attrs:{"color":"danger","icon":"ri-error-warning-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.errorText))]},proxy:true}],null,true)}):_c('figure',{key:"rq-code",staticClass:"scan-qr-code"},[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(isLoading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),(_vm.$mq.includes('mobile'))?_c('div',{staticClass:"scan-qr-code__title"},[_vm._v(" Scan this QR code in the Google Authentication app or type it manually ")]):_vm._e(),_c('div',{staticClass:"scan-qr-code__image-wrap",domProps:{"innerHTML":_vm._s(_vm.$utils.get(data, 'twoFaData.data.qr'))}}),_c('figcaption',{staticClass:"scan-qr-code__caption"},[(!_vm.$mq.includes('mobile'))?_c('div',{staticClass:"scan-qr-code__title"},[_vm._v(" Scan this QR code in the Google Authentication app or type it manually ")]):_vm._e(),_c('v-tooltip',{attrs:{"color":_vm.isCodeWasCopied ? 'success' : undefined,"open-delay":300,"open-on-hover":!_vm.isCodeWasCopied,"top":"","value":_vm.isCodeWasCopied},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('ui-a',_vm._g(_vm._b({staticClass:"scan-qr-code__copy",attrs:{"color":_vm.isCodeWasCopied ? 'success' : 'link',"tag":"button","type":"button"},on:{"click":function($event){_vm.onCopy(_vm.$utils.get(data, 'twoFaData.data.secret'))}}},'ui-a',attrs,false),on),[_c('v-icon',[_vm._v(" $ri-file-copy-line\" ")]),_vm._v(" "+_vm._s(_vm.$utils.get(data, 'twoFaData.data.secret'))+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.isCodeWasCopied ? 'Copied!' : 'Copy')+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }