
import { Component, Vue } from 'vue-property-decorator';
import { email, required, sameAs } from 'vuelidate/lib/validators';

/**
 * Форма сброса пароля
 */
@Component<FormResetPassword>({
  mounted() {
    this.formData.mail = (this.$route.query.email as string) || '';
  },

  validations: {
    formData: {
      mail: {
        email,
        required,
      },

      pass: {
        hasNumber(value) {
          return /\d+/.test(value);
        },

        hasUpperCase(value) {
          return /[A-Z]+/.test(value);
        },

        minLength(value) {
          return value && value.length >= 10;
        },

        required,
      },

      pass_confirmation: {
        required,
        sameAs: sameAs('pass'),
      },
    },
  },
})
export default class FormResetPassword extends Vue {
  /**
   * Данные формы
   */
  formData = {
    mail: '',
    pass: '',
    pass_confirmation: '',
  };

  /**
   * Отправка данных
   */
  onSubmit(): void {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      (this.$refs.FORM as HTMLFormElement).submit();
    }
  }
}
