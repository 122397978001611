
import { Component, Mixins, Prop } from 'vue-property-decorator';

import FormChangeEmail from '~/components/form-change-email';
import { UseEmailVerification } from '~/mixins';

/**
 * Модалка с формой подтверждения изменения почтового адреса
 */
@Component<ModalChangeEmail>({
  components: { FormChangeEmail },
})
export default class ModalChangeEmail extends Mixins(UseEmailVerification) {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;

  /**
   * Завершилась ли отправка формы
   */
  isDone = false;
}
