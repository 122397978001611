import Vue from 'vue';

import * as $api from '~/api';

declare module 'vue/types/vue' {
  interface Vue {
    //
    /**
     * Плагин для работы с API
     */
    $api: typeof $api;
  }
}

Vue.prototype.$api = $api;
