
import { Component, Vue } from 'vue-property-decorator';

import Modal2faDisabled from '~/components/modal-2fa-disabled';
import Modal2faEnabled from '~/components/modal-2fa-enabled';
import ModalGoogleVerify from '~/components/modal-google-verify';
import ModalGoogleVerifyDisable from '~/components/modal-google-verify-disable';

/**
 * Включение 2FA
 */
@Component<PanelGoogleVerify>({
  components: {
    Modal2faDisabled,
    Modal2faEnabled,
    ModalGoogleVerify,
    ModalGoogleVerifyDisable,
  },
})
export default class PanelGoogleVerify extends Vue {
  /**
   * Отображение модалки с формой
   */
  isShowFormModal = false;

  /**
   * Отображение модалки с сообщением
   */
  isShowMessageModal = false;
}
