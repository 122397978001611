import { SET } from '~/store/mutations';

export const namespaced = true;

/**
 * Layout-модуль
 */
interface LayoutState {
  //
  /**
   * Открыто ли выпадающее меню
   */
  isMenuOpen: boolean;

  /**
   * Видимость слайдера на страницах регистрации на устройствах с небольшим дисплеем
   */
  isSignUpSliderVisibleOnSmallScreens: boolean;
}

export const state = (): LayoutState => ({
  isMenuOpen: false,
  isSignUpSliderVisibleOnSmallScreens: true,
});

export const mutations = {
  SET_IS_MENU_OPEN: SET('isMenuOpen'),

  SET_IS_SIGN_UP_SLIDER_VISIBLE_ON_SMALL_SCREENS: SET(
    'isSignUpSliderVisibleOnSmallScreens',
  ),
};

export const actions = {
  /**
   * Меняет состояние открыто/закрыто у выпадающего меню
   *
   * @param context
   * @param context.commit
   * @param isMenuOpen Открыто ли меню
   */
  changeMenuState({ commit }, isMenuOpen: LayoutState['isMenuOpen']) {
    commit('SET_IS_MENU_OPEN', isMenuOpen);
  },

  /**
   * Меняет состояние открыто/закрыто у выпадающего меню
   *
   * @param context
   * @param context.commit
   * @param isVisible Отображение слайдера
   */
  changeSignUpSliderVisibility(
    { commit },
    isVisible: LayoutState['isSignUpSliderVisibleOnSmallScreens'],
  ) {
    commit('SET_IS_SIGN_UP_SLIDER_VISIBLE_ON_SMALL_SCREENS', isVisible);
  },
};
