var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":_vm.$api.User.getUser,"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [_c('ui-card',{staticClass:"account-summary",attrs:{"padding":!_vm.$mq.includes('mobile')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Account summary ")]},proxy:true}],null,true)},[_c('ui-data-list',[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(isLoading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),_c('ui-data-list-item',{attrs:{"prefix-icon":"ri-account-circle-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Account type ")]},proxy:true}],null,true)},[_c('div',{staticClass:"account-summary__account-type"},[_c('strong',{staticClass:"account-summary__account-type-name"},[_vm._v(" "+_vm._s(_vm.getRoles(_vm.$utils.get(data, 'user.data.roles')))+" ")]),(
                _vm.$mq.includes('laptop') &&
                _vm.$utils.getAvailableUpgradeType(
                  _vm.$utils.get(data, 'user.data.roles')
                )
              )?_c('account-summary-upgrade-link',{attrs:{"type":_vm.$utils.getAvailableUpgradeType(
                  _vm.$utils.get(data, 'user.data.roles')
                )}}):_vm._e()],1)]),(
            !_vm.$mq.includes('laptop') &&
            _vm.$utils.getAvailableUpgradeType(
              _vm.$utils.get(data, 'user.data.roles')
            )
          )?_c('ui-data-list-item',[_c('account-summary-upgrade-link',{attrs:{"type":_vm.$utils.getAvailableUpgradeType(
                _vm.$utils.get(data, 'user.data.roles')
              )}})],1):_vm._e(),_c('ui-data-list-item',{attrs:{"prefix-icon":"ri-calendar-event-fill"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Member for ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.getMembership(_vm.$utils.get(data, 'user.data.created')))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }