import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Проверяет включена ли у пользователя 2FA
 *
 * @param input
 * @param input.mail Почта пользователя
 */
export const check2faStatus = gql`
  query check2faStatus($input: any) {
    twoFaStatus(input: $input)
      @rest(
        path: "/spa/settings/2fa/check"
        type: "TwoFaStatus"
        method: "POST"
      ) {
      data
    }
  }
`;
