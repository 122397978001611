
import { Component, Mixins } from 'vue-property-decorator';

import FormResetPassword from '~/components/form-reset-password';
import { UseLaravelData } from '~/mixins';

/**
 * Страница входа
 */
@Component<Token>({
  components: {
    FormResetPassword,
  },

  metaInfo: {
    title: 'Reset password',
  },
})
export default class Token extends Mixins(UseLaravelData) {}
