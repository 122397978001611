
import { Component, Prop, Vue } from 'vue-property-decorator';

import FormGoogleVerify from '~/components/form-google-verify';

/**
 * Модалка с формой верификации google
 */
@Component<ModalGoogleVerify>({
  components: { FormGoogleVerify },
})
export default class ModalGoogleVerify extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;
}
