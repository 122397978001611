var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":_vm.$api.User.getUser,"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var userData = ref.result.data;
var isLoading = ref.isLoading;
return [_c('ui-card',{attrs:{"padding":false}},[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(isLoading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),_c('ui-sidebar-menu',{staticClass:"menu-account"},[_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name === 'index',"prefix-icon":"ri-account-circle-fill"},on:{"click":function($event){return _vm.$router.push({ name: 'index' })}}},[_vm._v(" My profile ")]),_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name === 'settings',"prefix-icon":"ri-settings-3-fill"},on:{"click":function($event){return _vm.$router.push({ name: 'settings' })}}},[_vm._v(" Settings ")]),(
            !_vm.$utils
              .get(userData, 'user.data.roles', [])
              .includes('premium') && !isLoading
          )?_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name === 'verification',"prefix-icon":"ri-shield-flash-fill"},on:{"click":function($event){return _vm.$router.push({ name: 'verification' })}}},[_vm._v(" Verification ")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }