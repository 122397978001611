import { Vue } from 'vue-property-decorator';

/**
 * Универсальный мутатор
 *
 * @param key Ключ в state текущего модуля
 */
export default (key: string) => (
  state: { [key: string]: any },
  value: any,
): void => {
  Vue.set(state, key, value);
};
