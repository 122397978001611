
import { Component, Mixins } from 'vue-property-decorator';

import FormLogout from '~/components/form-logout';
import { UseEmailVerification, UseLaravelData } from '~/mixins';

/**
 * Страница запроса на верификацию
 */
@Component<Verify>({
  components: {
    FormLogout,
  },

  metaInfo: {
    title: 'Verify email',
  },
})
export default class Verify extends Mixins(
  UseLaravelData,
  UseEmailVerification,
) {}
