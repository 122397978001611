
import { Component, Mixins } from 'vue-property-decorator';

import FormRegister from '~/components/form-register';
import { UseLaravelData } from '~/mixins';

/**
 * Страница входа
 */
@Component<Register>({
  components: {
    FormRegister,
  },

  metaInfo: {
    title: 'Register',
  },
})
export default class Register extends Mixins(UseLaravelData) {}
