import { moment } from '~/utils';

/**
 * Возвращает разницу между датами
 *
 * @param from Дата начала
 * @param to Дата завершения
 */
export function getTimeDifference(from, to): string {
  const diff = moment(to).diff(moment(from));
  const duration = moment.duration(diff);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let label = '';

  if (years) label += `${months} y `;
  if (months) label += `${months} m `;
  if (days) label += `${days} d `;
  if (hours) label += `${hours} h `;
  if (minutes) label += `${minutes} min `;

  label += `${seconds} sec`;

  return label;
}
