import { render, staticRenderFns } from "./modal-error.component.vue?vue&type=template&id=323ad770&scoped=true&"
import script from "./modal-error.component.vue?vue&type=script&lang=ts&"
export * from "./modal-error.component.vue?vue&type=script&lang=ts&"
import style0 from "./modal-error.component.vue?vue&type=style&index=0&id=323ad770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323ad770",
  null
  
)

export default component.exports