import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Генерация секретного ключа и QR-кода
 */
export const generate2faData = gql`
  query generate2faData {
    twoFaData
      @rest(
        path: "/spa/settings/2fa/enable"
        type: "TwoFaData"
        method: "GET"
      ) {
      data
    }
  }
`;
