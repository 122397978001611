import { Component, Vue } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

/**
 * Миксин рекапчи
 */
@Component<UseRecaptcha>({
  beforeDestroy() {
    this.removeRecaptcha();
  },

  components: {
    VueRecaptcha,
  },
})
export default class UseRecaptcha extends Vue {
  /**
   * Ключ сайта
   */
  readonly sitekey: string = this.$env.VUE_APP_INVISIBLE_RECAPTCHA_SITEKEY;

  /**
   * Меняет name у поля с рекапчей
   */
  changeRecaptchaFieldName(): void {
    const textarea: HTMLTextAreaElement | null = document.querySelector(
      'textarea[name="g-recaptcha-response"]',
    );

    if (textarea) textarea.name = 'recaptcha_token';
  }

  /**
   * Выполнение рекапчи
   */
  executeRecaptcha(): void {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.changeRecaptchaFieldName();
      (this.$refs.RECAPTCHA as VueRecaptcha).execute();
    }
  }

  /**
   * Удаляет рекапчи
   */
  removeRecaptcha() {
    (document.querySelector('.grecaptcha-badge') as HTMLElement)?.remove();
  }
}
