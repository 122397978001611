
import { Component, Mixins } from 'vue-property-decorator';

import AccountVerification from '~/components/account-verification';
import { UseLaravelData } from '~/mixins';

/**
 * Верификации аккаунта
 */
@Component<Verification>({
  components: { AccountVerification },

  metaInfo: {
    title: 'Verification',
  },
})
export default class Verification extends Mixins(UseLaravelData) {}
