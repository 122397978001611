
import { Component, Mixins } from 'vue-property-decorator';

import { UsePublic } from '~/mixins';

/**
 * Меню политик конфиденциальности (мобильное)
 */
@Component<MenuLegalMobile>({
  created() {
    this.selectValue = this.currentPolicy;
  },
})
export default class MenuLegalMobile extends Mixins(UsePublic) {
  /**
   * Значение селекта
   */
  selectValue = '';

  /**
   * Текущая страница
   */
  get currentPolicy(): string {
    if (this.$route.name.includes('privacy-policy')) {
      return 'privacy-policy';
    }

    if (this.$route.name.includes('cookie-policy')) {
      return 'cookie-policy';
    }

    if (this.$route.name.includes('aml-policy')) {
      return 'aml-policy';
    }

    if (this.$route.name.includes('verification-levels-explained')) {
      return 'verification-levels-explained';
    }

    return '';
  }
}
