import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Получение информации о пользователе
 */
export const getUser = gql`
  query getUser {
    user @rest(path: "/spa/profile", type: "UserData", method: "GET") {
      data @type(name: "User")
    }
  }
`;
