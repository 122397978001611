var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-mutation',{attrs:{"mutation":_vm.$api.Settings.enable2fa,"tag":"","variables":{ input: _vm.formData }},on:{"done":function($event){return _vm.$emit('done')},"error":function (error) {
      _vm.errorText =
        _vm.$utils.get(error, 'networkError.result.errors.code[0]') ||
        _vm.$utils.get(error, 'networkError.result.message');

      _vm.formData.code = '';
      _vm.$refs['TYPE_CODE'].focus();
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
    var loading = ref.loading;
return [_c('form',{staticClass:"form-google-verify",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {
          _vm.nextStep() || (!_vm.isNextStepDisabled && mutate());
        }).apply(null, arguments)}}},[_c('p',{staticClass:"form-google-verify__title"},[_vm._v("Activate Google 2FA")]),_c('span',{staticClass:"form-google-verify__subtitle"},[_vm._v(" Account security is very important. Set-up second factor authentication (2FA) on your account. ")]),_c('div',{staticClass:"form-google-verify__content"},[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(loading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),_c('v-expand-transition',[(_vm.errorText)?_c('ui-alert',{staticClass:"[ mb-4 ]",attrs:{"color":"danger","icon":"ri-error-warning-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.errorText))]},proxy:true}],null,true)}):_vm._e()],1),_c('v-fade-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[(_vm.currentStep === 1)?_c('install-google-verificator'):(_vm.currentStep === 2)?_c('scan-qr-code',{on:{"done":function($event){_vm.isNextStepDisabled = false},"hook:created":function($event){_vm.isNextStepDisabled = true}}}):(_vm.currentStep === 3)?_c('store-key-code',{on:{"done":function($event){_vm.isNextStepDisabled = false}}}):(_vm.currentStep === 4)?_c('type-code',{ref:"TYPE_CODE",on:{"hook:created":function($event){_vm.isNextStepDisabled = true}},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}):_vm._e()],1)],1),_c('nav',{staticClass:"form-google-verify__nav"},[_c('ui-a',{staticStyle:{"font-size":"20px"},attrs:{"tag":"button","type":"button"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" Cancel ")]),_c('ui-button',{attrs:{"color":"success","disabled":_vm.isNextStepDisabled || loading,"size":"giant","type":"button"},on:{"click":function($event){_vm.nextStep() || mutate()}}},[_vm._v(" Proceed to next step ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }