var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":_vm.$api.User.getUser,"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
var getUserQuery = ref.query;
return [_c('v-expansion-panel',{staticClass:"panel-google-verify"},[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(isLoading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),_c('v-expansion-panel-header',{attrs:{"hide-actions":"","hide-suffix-icon":""},on:{"click":function($event){_vm.isShowFormModal = !_vm.isShowFormModal}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('ui-expansion-panel-header',{attrs:{"hide-suffix-icon":"","is-open":open},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Use free Google's Android or iPhone app to add token-based 2FA. ")]},proxy:true}],null,true)},[_c('span',{staticStyle:{"color":"var(--ui-color-text)"}},[_vm._v(" Google 2FA ")]),_c('ui-switcher',{attrs:{"checked":_vm.$utils.get(data, "user.data['2fa']"),"disabled":Boolean(isLoading)}})],1),_c(_vm.$utils.get(data, "user.data['2fa']")
                ? 'modal-google-verify-disable'
                : 'modal-google-verify',{tag:"component",on:{"done":function () {
                _vm.isShowFormModal = false;
                _vm.isShowMessageModal = true;
              }},model:{value:(_vm.isShowFormModal),callback:function ($$v) {_vm.isShowFormModal=$$v},expression:"isShowFormModal"}}),_c(_vm.$utils.get(data, "user.data['2fa']")
                ? 'modal-2fa-disabled'
                : 'modal-2fa-enabled',{tag:"component",on:{"done":function($event){return getUserQuery.refetch()}},model:{value:(_vm.isShowMessageModal),callback:function ($$v) {_vm.isShowMessageModal=$$v},expression:"isShowMessageModal"}})]}},{key:"actions",fn:function(){return undefined},proxy:true}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }