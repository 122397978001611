
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Ссылка на сервис
 */
@Component<ServiceLink>({
  inheritAttrs: false,
})
export default class ServiceLink extends Vue {
  /**
   * Иконка
   */
  @Prop({ type: String })
  readonly icon!: string;
}
