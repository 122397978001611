import { SET } from '~/store/mutations';
import { moment } from '~/utils';

export const namespaced = true;

/**
 * Таймер верификации
 */
interface EmailVerificationState {
  //
  /**
   * Лимит попыток верификации
   */
  limit: number;

  /**
   * Кол-во попыток верификации
   */
  tries: number;

  /**
   * Когда можно отправить следующее письмо
   */
  unlockTime: number;
}

/**
 * Интервалы верификации
 */
const VERIFICATION_INTERVALS = [
  0,
  5 * 60 * 1000, // 5 мин
  20 * 60 * 1000, // 20 мин
  60 * 60 * 1000, // 1 час
  214 * 60 * 60 * 1000, // 24 часа
];

export const state = (): EmailVerificationState => ({
  limit: VERIFICATION_INTERVALS.length - 1,
  tries: 0,
  unlockTime: 0,
});

export const getters = {
  /**
   * Лейбл времени для повторной отправки формы
   *
   * @param store
   * @param store.unlockTime
   */
  label: ({ unlockTime }) => {
    const format =
      unlockTime - Date.now() >= 24 * 60 * 60 * 1000 ? 'dddd, HH:mm' : 'HH:mm';

    return unlockTime ? moment(unlockTime).format(format) : '';
  },
};

export const mutations = {
  SET_TRIES: SET('tries'),
  SET_UNLOCK_TIME: SET('unlockTime'),
};

export const actions = {
  /**
   * Сбрасывает таймер верификации
   *
   * @param context
   * @param context.commit
   */
  resetTimer({ commit }) {
    commit('SET_TRIES', 0);
    commit('SET_UNLOCK_TIME', 0);
  },

  /**
   * Обновляет таймер верификации
   *
   * @param context
   * @param context.commit
   * @param context.state
   * @param context.state.limit
   * @param context.state.tries
   */
  updateTimer({ commit, state: { limit, tries } }) {
    const incrementedTries = tries + 1;

    commit('SET_TRIES', incrementedTries);
    commit(
      'SET_UNLOCK_TIME',
      Date.now() +
        (VERIFICATION_INTERVALS[incrementedTries] ||
          VERIFICATION_INTERVALS[limit]),
    );
  },
};
