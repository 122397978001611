
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Инпут для ввода кода 2FA
 */
@Component<TypeCode>({
  mounted() {
    this.focus();
  },
})
export default class TypeCode extends Vue {
  /**
   * Входное значение
   */
  @Prop({ required: true })
  readonly value!: string | null;

  /**
   * Устанавливает фокус на инпуте
   */
  focus() {
    (this.$refs.INPUT as any).focus();
  }
}
