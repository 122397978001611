
import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import TypeCode from '~/components/type-code';

/**
 * Форма отмены верификации google
 */
@Component<FormGoogleVerifyDisable>({
  components: {
    TypeCode,
  },

  validations: {
    formData: {
      code: {
        required,
      },
    },
  },
})
export default class FormGoogleVerifyDisable extends Vue {
  /**
   * Текст ошибки
   */
  errorText = '';

  /**
   * Данные формы
   */
  formData = {
    code: '',
  };

  /**
   * Сброс и закрытие формы
   */
  onCancel(): void {
    this.$emit('close');
    this.errorText = '';
    this.formData.code = '';
  }
}
