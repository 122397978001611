
import { Component, Vue } from 'vue-property-decorator';

/**
 * Последняя активность
 */
@Component<RecentActivity>({})
export default class RecentActivity extends Vue {
  /**
   * Текущая страница
   */
  currentPage = 1;

  /**
   * Заголовок таблицы
   */
  headers = [
    {
      align: 'start',
      sortable: false,
      text: 'Date',
      value: 'date',
    },
    {
      align: 'start',
      sortable: false,
      text: 'Action',
      value: 'action',
    },
    {
      align: 'start',
      sortable: false,
      text: 'Browser',
      value: 'browser',
    },
    {
      align: 'start',
      sortable: false,
      text: 'IP address',
      value: 'ip',
    },
    {
      align: 'start',
      sortable: false,
      text: 'Location',
      value: 'location',
    },
  ];

  /**
   * Определяет имя иконки браузера
   *
   * @example
   *   ```ts
   *   getBrowserIconName('Chrome (Linux)') => 'chrome'
   *   ```;
   *
   * @param browser Информация о браузере
   */
  getBrowserIconName(browser: string) {
    const info = String(browser).toLowerCase();

    if (info.includes('chrome')) return 'ri-chrome-line';
    if (info.includes('firefox')) return 'ri-firefox-line';
    if (info.includes('safari')) return 'ri-safari-line';
    if (info.includes('explorer')) return 'ri-explorer-line';

    return '';
  }
}
