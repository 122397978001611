import { Component, Vue } from 'vue-property-decorator';

/**
 * Копирование текста
 */
@Component<UseCopy>({})
export default class UseCopy extends Vue {
  /**
   * Был ли код скопирован
   */
  isCodeWasCopied = false;

  /**
   * Копирует текст
   *
   * @param text Текст для копирования
   */
  onCopy(text: string): void {
    this.$utils.copyStringToBuffer(text, this.$el);
    this.isCodeWasCopied = true;
    setTimeout(() => {
      this.isCodeWasCopied = false;
    }, 1000);
  }
}
