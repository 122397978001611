
import { Component, Mixins } from 'vue-property-decorator';

import { UseLaravelData } from '~/mixins';

/**
 * Страница запроса на верификацию аккаунта
 */
@Component<VerifyYourAccount>({
  metaInfo: {
    title: 'Verify your account',
  },
})
export default class VerifyYourAccount extends Mixins(UseLaravelData) {}
