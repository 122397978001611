import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Изменение почтового адреса пользователя
 *
 * @param data
 */
export const setSettingsEmail = gql`
  mutation setSettingsEmail($input: Any) {
    setSettingsEmail(input: $input)
      @rest(
        path: "/spa/settings/changeEmail"
        type: "SetSettingsEmail"
        method: "POST"
      ) {
      status
      errors
      message
    }
  }
`;
