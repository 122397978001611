
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const FormsData = namespace('formsData');

/**
 * Форма логаута
 */
@Component
export default class FormLogout extends Vue {
  @FormsData.Action('clearFormsData')
  readonly clearFormsData;

  /**
   * Отправка формы
   */
  submit() {
    this.clearFormsData();

    (this.$refs.FORM as HTMLFormElement).submit();
  }
}
