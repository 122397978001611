var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-mutation',{attrs:{"mutation":_vm.$api.Settings.disable2fa,"tag":"","variables":{ input: _vm.formData }},on:{"done":function($event){return _vm.$emit('done')},"error":function (error) {
      _vm.formData.code = '';

      _vm.errorText =
        _vm.$utils.get(error, 'networkError.result.errors.code[0]') ||
        _vm.$utils.get(error, 'networkError.result.message');

      _vm.$refs['TYPE_CODE'].focus();
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
    var loading = ref.loading;
return [_c('form',{staticClass:"form-google-verify-disable",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {
          _vm.formData.code.length === 6 && mutate();
        }).apply(null, arguments)}}},[_c('p',{staticClass:"form-google-verify-disable__title"},[_vm._v("Disable Google 2FA")]),_c('span',{staticClass:"form-google-verify-disable__subtitle"},[_vm._v(" Disable second factor authentication (2FA) on your account. ")]),_c('div',{staticClass:"form-google-verify-disable__content"},[_c('v-overlay',{attrs:{"absolute":"absolute","color":"white","opacity":1,"value":Boolean(loading)}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":48}})],1),_c('v-expand-transition',[(_vm.errorText)?_c('ui-alert',{staticClass:"[ mb-4 ]",attrs:{"color":"danger","icon":"ri-error-warning-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.errorText))]},proxy:true}],null,true)}):_vm._e()],1),_c('type-code',{ref:"TYPE_CODE",model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1),_c('nav',{staticClass:"form-google-verify-disable__nav"},[_c('ui-a',{staticStyle:{"font-size":"20px"},attrs:{"tag":"button","type":"button"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" Cancel ")]),_c('ui-button',{staticStyle:{"min-width":"192px"},attrs:{"color":"danger","disabled":_vm.formData.code.length !== 6 || loading,"size":"giant","type":"button"},on:{"click":function($event){return mutate()}}},[_vm._v(" Disable ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }