
import { Component, Mixins } from 'vue-property-decorator';

import { UseCopy } from '~/mixins';

/**
 * Сохранение ключа
 */
@Component<StoreKeyCode>({})
export default class StoreKeyCode extends Mixins(UseCopy) {
  /**
   * Был ли код скопирован
   */
  isCodeWasCopied = false;
}
