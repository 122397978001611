
import { Component, Mixins } from 'vue-property-decorator';
import { required, sameAs } from 'vuelidate/lib/validators';

import Modal2faCode from '~/components/modal-2fa-code';
import { Use2faCode } from '~/mixins';

/**
 * Форма смены пароля
 */
@Component<FormChangePassword>({
  components: { Modal2faCode },

  validations: {
    formData: {
      new_pass: {
        hasNumber(value) {
          return /\d+/.test(value);
        },

        hasUpperCase(value) {
          return /[A-Z]+/.test(value);
        },

        minLength(value) {
          return value && value.length >= 10;
        },

        required,
      },

      new_pass_confirmation: {
        required,
        sameAs: sameAs('new_pass'),
      },

      pass: {
        hasNumber(value) {
          return /\d+/.test(value);
        },

        hasUpperCase(value) {
          return /[A-Z]+/.test(value);
        },

        minLength(value) {
          return value && value.length >= 10;
        },

        required,
      },
    },
  },
})
export default class FormChangePassword extends Mixins(Use2faCode) {
  /**
   * Дефолтные данные формы
   */
  defaultFormData = {
    code: '',
    new_pass: '',
    new_pass_confirmation: '',
    pass: '',
  };

  /**
   * Ошибки серверной валидации
   */
  errors = {};

  /**
   * Данные формы
   */
  formData = {
    code: '',
    new_pass: '',
    new_pass_confirmation: '',
    pass: '',
  };

  /**
   * Идет ли загрузка данных
   */
  isLoading = false;

  /**
   * Отменяет отправку формы
   */
  onCancel() {
    this.resetValidation();
    this.formData = { ...this.defaultFormData };
    this.$emit('cancel');
  }

  /**
   * Сбрасывает валидацию
   */
  resetValidation() {
    this.errors = {};
    this.$v.$reset();
  }

  /**
   * Отправляет данные
   */
  submitForm() {
    this.errors = {};
    this.isLoading = true;

    this.$apollo
      .mutate({
        mutation: this.$api.Settings.setSettingsPassword,
        variables: { input: this.formData },
      })
      .then(() => {
        this.$emit('done');

        return true;
      })
      .catch((error) => {
        this.errors = {
          ...this.$utils.get(error, 'networkError.result.errors'),
          message: this.$utils.get(error, 'networkError.result.message'),
        };
        (this.$refs.MODAL_2FA_CODE as Modal2faCode).resetForm();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Пытается отправить форму, проверяя валидацию и 2ФА
   *
   * @param mail Почта пользователя
   */
  async submitFormWith2faCheck(mail: string): Promise<void> {
    const isFormValid = await this.validate();

    if (isFormValid) {
      this.check2faStatus(mail)
        .then((isCodeRequired) => {
          if (!isCodeRequired) this.submitForm();

          return true;
        })
        .catch(() => false);
    }
  }

  /**
   * Валидация данных
   */
  async validate(): Promise<boolean> {
    this.$v.$touch();

    return !this.$v.$invalid;
  }
}
