import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const EmailVerification = namespace('emailVerification');

/**
 * Миксин для проверки блокировки верификации
 */
@Component<UseEmailVerification>({
  created() {
    this.setVerificationTimer();
    this.checkIsVerificationReady();

    if (!this.isVerificationReady) {
      this.setVerificationInterval(1000);
      this.setTimerLabel();
      this.setTimerLabelInterval(1000);
    }
  },

  destroyed() {
    clearInterval(this.checkVerificationInterval);
    clearInterval(this.timerLabelInterval);
  },
})
export default class UseEmailVerification extends Vue {
  @EmailVerification.State('tries')
  readonly tries;

  @EmailVerification.State('unlockTime')
  readonly unlockTime;

  @EmailVerification.Action('updateTimer')
  readonly updateTimer;

  /**
   * Интервал проверки блокировки верификации
   */
  checkVerificationInterval = undefined;

  /**
   * Если истина то верификация будет разблокирована
   */
  isVerificationReady = false;

  /**
   * Лейбл таймера
   */
  timerLabel = '--:--';

  /**
   * Интервал формирования лейбла таймера
   */
  timerLabelInterval = undefined;

  /**
   * Проверка блокировки верификации
   */
  checkIsVerificationReady(): void {
    this.isVerificationReady = Date.now() >= this.unlockTime;
  }

  /**
   * Отправляет письмо с верификацией на почту
   *
   * @param reference Ref-атрибут формы
   */
  resend(reference: string): void {
    this.updateTimer();

    try {
      (this.$refs[reference] as HTMLFormElement).submit();
    } catch {
      console.warn('Не удалось найти форму для отправки верификации');
    }
  }

  /**
   * Устанавливает начальный лейбл таймера
   */
  setTimerLabel(): void {
    if (this.unlockTime - Date.now() > 0) {
      this.timerLabel = this.$utils.getTimeDifference(
        Date.now(),
        this.unlockTime,
      );
    }
  }

  /**
   * Устанавливает интервал формирования лейбла таймера
   *
   * @param interval Интервал проверки, мс
   */
  setTimerLabelInterval(interval = 1000): void {
    this.timerLabelInterval = setInterval(this.setTimerLabel, interval);
  }

  /**
   * Устанавливает интервал проверки блокировки верификации
   *
   * @param interval Интервал проверки, мс
   */
  setVerificationInterval(interval = 1000): void {
    this.checkVerificationInterval = setInterval(
      this.checkIsVerificationReady,
      interval,
    );
  }

  /**
   * Устанавливает начальные значения таймера верификации
   */
  setVerificationTimer(): void {
    if (!this.tries) {
      this.updateTimer();
    }
  }
}
