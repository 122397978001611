import {
  ApolloClient,
  ApolloLink,
  buildAxiosFetch,
  InMemoryCache,
  onError,
  RestLink,
  VueApollo,
} from '@alexlit/vue-apollo-kit';
import { formDataBodySerializer } from '@alexlit/vue-apollo-kit/body-serializers';
import Vue from 'vue';

import { $axios } from '~/plugins/axios';
import { cloneDeep } from '~/utils';

Vue.use(VueApollo);

/**
 * Error
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // silent
  }

  if (
    networkError &&
    `${networkError}` !== 'TypeError: forward is not a function'
  ) {
    const { response, result, statusCode } = networkError as any;

    console.error('[Apollo:NetworkError]:', `${networkError}`, {
      response,
      result,
      statusCode,
    });
  }
});

/**
 * Cache
 */
const cache = new InMemoryCache();

/**
 * Rest
 */
const restLink = new RestLink({
  bodySerializers: {
    formData: formDataBodySerializer,
  },

  /**
   * Использование axios-плагина вместо ванильного fetch
   *
   * @param uri
   * @param options
   */
  customFetch: (uri, options) =>
    (buildAxiosFetch as any)($axios, (config) => {
      const newConfig = cloneDeep(config);

      /**
       * Заголовок сформированный apollo, например при использовании параметра
       * 'formDataBodySerializer'
       */
      const apolloHeaders: Record<string, string> = Object.fromEntries(
        (options.headers as any).entries(),
      );

      newConfig.headers = { ...newConfig.headers, ...apolloHeaders };

      return newConfig;
    })(uri, options),

  uri: '',
});

/**
 * Client
 */
const defaultClient = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, restLink]),
  resolvers: {},
  typeDefs: [],
});

/**
 * Provider
 */
const apollo = new VueApollo({
  defaultClient,
});

export default apollo;
