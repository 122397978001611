
import { Component, Prop, Vue } from 'vue-property-decorator';

import FormGoogleVerifyDisable from '~/components/form-google-verify-disable';

/**
 * Модалка с формой верификации google
 */
@Component<ModalGoogleVerifyDisable>({
  components: { FormGoogleVerifyDisable },
})
export default class ModalGoogleVerifyDisable extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;
}
