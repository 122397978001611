
import { Component, Vue } from 'vue-property-decorator';

import AppFooter from '~/components/app-footer';
import AppHeader from '~/components/app-header';
import CookieAlertBanner from '~/components/cookie-alert-banner';

/**
 * Дефолтный шаблон
 */
@Component<Default>({
  components: {
    AppFooter,
    AppHeader,
    CookieAlertBanner,
  },

  metaInfo() {
    return {
      changed: (newInfo) => {
        this.title = newInfo?.title || 'Loading...';
      },
    };
  },
})
export default class Default extends Vue {
  /**
   * Заголовок страницы
   */
  title = '';
}
