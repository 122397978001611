var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-dialog',{attrs:{"content-class":"modal-change-password","value":_vm.value},on:{"input":function (value) {
      _vm.isShowMessage = false;
      if (_vm.$refs.FORM) { _vm.$refs.FORM.resetValidation(); }
      _vm.$emit('input', value);
    }}},[_c('v-scroll-y-reverse-transition',{attrs:{"leave-absolute":""}},[(!_vm.isShowMessage)?_c('form-change-password',{ref:"FORM",on:{"cancel":function($event){return _vm.$emit('input', false)},"done":function($event){_vm.isShowMessage = true}}}):_c('ui-message-action',{scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"alt":"password","height":"80px","src":require('~/assets/images/password.svg')}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("Your password has been changed!")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Your Alfacash password has been changed. ")]},proxy:true},{key:"footer",fn:function(){return [_c('ui-button',{staticClass:"modal-change-password__button",attrs:{"color":"success","size":"giant"},on:{"click":function () {
              _vm.isShowMessage = false;
              _vm.$emit('input', false);
            }}},[_vm._v(" Ok ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }