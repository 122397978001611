
import { Component, Prop, Vue } from 'vue-property-decorator';

import AccountDropdown from '~/components/account-dropdown';
import ServicesDropdown from '~/components/services-dropdown';

/**
 * Хедер
 */
@Component<AppHeader>({
  components: {
    AccountDropdown,
    ServicesDropdown,
  },

  inheritAttrs: false,
})
export default class AppHeader extends Vue {
  /**
   * Скрытие меню
   */
  @Prop({ default: false, type: Boolean })
  readonly hideMenu!: boolean;
}
