
import { Component, Vue } from 'vue-property-decorator';

import PanelChangeEmail from '~/components/panel-change-email';
import PanelChangePassword from '~/components/panel-change-password';
import PanelDeleteAccount from '~/components/panel-delete-account';
import PanelGoogleVerify from '~/components/panel-google-verify';

/**
 * Настройки пользователя
 */
@Component<UserSettings>({
  components: {
    PanelChangeEmail,
    PanelChangePassword,
    PanelDeleteAccount,
    PanelGoogleVerify,
  },
})
export default class UserSettings extends Vue {}
