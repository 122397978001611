import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import type { RawLocation, Route } from 'vue-router';
import VueRouter from 'vue-router';

import { routes } from './routes';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

declare module 'vue/types/vue' {
  interface Vue {
    beforeRouteEnter?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void,
    ): void;

    beforeRouteLeave?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void,
    ): void;

    beforeRouteUpdate?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void,
    ): void;
  }
}

Vue.use(VueRouter);

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,

  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
