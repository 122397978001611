
import { Component, Mixins, Prop } from 'vue-property-decorator';

import ServiceLink from '~/components/service-link';
import { UsePublic } from '~/mixins';

/**
 * Футер
 */
@Component<AppFooter>({
  components: { ServiceLink },
})
export default class AppFooter extends Mixins(UsePublic) {
  /**
   * Скрытие ссылки на политику конфиденциальности
   */
  @Prop({ default: false, type: Boolean })
  readonly hideLegal!: boolean;

  /**
   * Года копирайта
   */
  get years(): string {
    const currentYear = new Date().getFullYear();

    return currentYear > 2020 ? `2020 - ${currentYear}` : `${currentYear}`;
  }
}
