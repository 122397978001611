
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import LayoutError from '~/layouts/error.vue';
import PageError401 from '~/pages/error/401.vue';
import PageError403 from '~/pages/error/403.vue';
import PageError404 from '~/pages/error/404.vue';
import PageError419 from '~/pages/error/419.vue';
import PageError429 from '~/pages/error/429.vue';
import PageError500 from '~/pages/error/500.vue';
import PageError503 from '~/pages/error/503.vue';

const Layout = namespace('layout');

/**
 * Основной компонент приложения
 */
@Component<App>({
  components: {
    LayoutError,
    PageError401,
    PageError403,
    PageError404,
    PageError419,
    PageError429,
    PageError500,
    PageError503,
  },
})
export default class App extends Vue {
  @Layout.State('isMenuOpen')
  readonly isMenuOpen;
}
