
import { Component, Vue } from 'vue-property-decorator';

import AccountSummaryUpgradeLink from './components/account-summary-upgrade-link';

/**
 * Данные по аккаунту
 */
@Component<AccountSummary>({
  components: { AccountSummaryUpgradeLink },
})
export default class AccountSummary extends Vue {
  /**
   * Вычисление стажа
   *
   * @example
   *   getMembership('2020-09-09 09:46:13') => '1 day'
   *
   * @param date Дата начала стажа
   */
  getMembership(date: string): string {
    const diff = this.$utils.moment().diff(date);
    const duration = this.$utils.moment.duration(diff);
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    let label = '';

    if (years) label += `${years} years `;
    if (months) label += `${months} months `;

    label += `${days} days `;

    return date ? label : '';
  }

  /**
   * Возвращает роли пользователя из списка
   *
   * @example
   *   getRoles(['registered']) => 'Registered'
   *   getRoles(['registered', 'standard', 'premium']) => 'Standard, Premium'
   *
   * @param rolesArray Список ролей
   */
  getRoles(rolesArray: string[] = []): string {
    const roles = rolesArray.map((role) => this.$utils.upperFirst(role));

    if (roles.length > 1) {
      const indexOfRegistered = roles.indexOf('Registered');

      if (indexOfRegistered !== -1) {
        roles.splice(indexOfRegistered, 1);
      }
    }

    return roles.join(', ');
  }
}
