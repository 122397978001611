
import { Component, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import InstallGoogleVerificator from '~/components/install-google-verificator';
import ScanQrCode from '~/components/scan-qr-code';
import StoreKeyCode from '~/components/store-key-code';
import TypeCode from '~/components/type-code';

/**
 * Форма верификации google
 */
@Component<FormGoogleVerify>({
  components: {
    InstallGoogleVerificator,
    ScanQrCode,
    StoreKeyCode,
    TypeCode,
  },

  validations: {
    formData: {
      code: {
        required,
      },
    },
  },
})
export default class FormGoogleVerify extends Vue {
  /**
   * Текущий шаг
   */
  currentStep = 1;

  /**
   * Текст ошибки
   */
  errorText = '';

  /**
   * Данные формы
   */
  formData = {
    code: '',
  };

  /**
   * Блокировка кнопки следующего шага
   */
  isNextStepDisabled = false;

  /**
   * Кол-во шагов
   */
  steps = 4;

  /**
   * Блокирует кнопку следующего шага при невалидном коде
   *
   * @param newValue
   */
  @Watch('formData.code', { immediate: true })
  onFormDataCodeChange(newValue: FormGoogleVerify['formData']['code']): void {
    if (this.currentStep === 4 && newValue.length === 6) {
      this.isNextStepDisabled = false;
    } else if (this.currentStep === 4 && newValue.length !== 6) {
      this.isNextStepDisabled = true;
    }
  }

  /**
   * Переход к следующему шагу
   *
   * @returns Был ли осуществлен переход (ложь если шаг последний)
   */
  nextStep(): boolean {
    if (this.currentStep < this.steps) {
      this.currentStep += 1;
      this.errorText = '';

      return true;
    }

    return false;
  }

  /**
   * Сброс и закрытие формы
   */
  onCancel(): void {
    this.$emit('close');
    setTimeout(() => {
      this.isNextStepDisabled = false;
      this.currentStep = 1;
      this.errorText = '';
      this.formData.code = '';
    }, 300);
  }
}
