/* eslint-disable unicorn/prevent-abbreviations */
import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    //
    /**
     * Плагин доступа к переменным окружения
     */
    $env: typeof process.env;
  }
}

Vue.prototype.$env = process.env;
