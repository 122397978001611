var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-card',{attrs:{"padding":false}},[_c('ui-sidebar-menu',{staticClass:"menu-legal"},[_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name.includes('privacy-policy')},on:{"click":function($event){return _vm.$router.push({
          name: ((!_vm.isRoutePublic ? 'legal' : 'public') + "-privacy-policy"),
        })}}},[_vm._v(" Privacy Policy ")]),_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name.includes('cookie-policy')},on:{"click":function($event){return _vm.$router.push({
          name: ((!_vm.isRoutePublic ? 'legal' : 'public') + "-cookie-policy"),
        })}}},[_vm._v(" Cookie Policy ")]),_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name.includes('aml-policy')},on:{"click":function($event){return _vm.$router.push({
          name: ((!_vm.isRoutePublic ? 'legal' : 'public') + "-aml-policy"),
        })}}},[_vm._v(" AML Policy ")]),_c('ui-sidebar-menu-item',{attrs:{"active":_vm.$route.name.includes('verification-levels-explained')},on:{"click":function($event){return _vm.$router.push({
          name: ((!_vm.isRoutePublic ? 'legal' : 'public') + "-verification-levels-explained"),
        })}}},[_vm._v(" Verification Levels Explained ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }