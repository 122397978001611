import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import Vue from 'vue';

import router from '~/router';

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosInstance;
  }
}

/**
 * Обработчик ошибок
 *
 * @param status
 */
function onError(status: number): void {
  if ([401, 403, 404, 419, 500, 503].includes(status)) {
    router.replace({
      name: `error-${status}`,
    });
  }
}

/**
 * Кастомный инстанс axios
 */
const $axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Pre-request hook
 */
$axios.interceptors.request.use(
  (config: AxiosRequestConfig) => config,
  (error) => Promise.reject(error),
);

/**
 * Pre-response hook
 */
$axios.interceptors.response.use(
  (response: AxiosResponse) => {
    onError(response.status);

    return response;
  },
  (error) => {
    onError(error.response.status);

    return Promise.reject(error);
  },
);

Vue.prototype.$axios = $axios;

export { $axios };

export { AxiosRequestConfig, AxiosResponse } from 'axios';
