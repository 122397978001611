import { render, staticRenderFns } from "./carousel-sign-up.component.vue?vue&type=template&id=1587b2b3&scoped=true&"
import script from "./carousel-sign-up.component.vue?vue&type=script&lang=ts&"
export * from "./carousel-sign-up.component.vue?vue&type=script&lang=ts&"
import style0 from "./carousel-sign-up.component.vue?vue&type=style&index=0&id=1587b2b3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1587b2b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCarousel,VCarouselItem,VFadeTransition,VImg})
