
import { Component, Vue } from 'vue-property-decorator';

import AppHeader from '~/components/app-header';
import CookieAlertBanner from '~/components/cookie-alert-banner';

/**
 * Шаблон логина/регистрации
 */
@Component<Login>({
  components: {
    AppHeader,
    CookieAlertBanner,
  },
})
export default class Login extends Vue {}
