
import { Component, Mixins } from 'vue-property-decorator';

import { UseLaravelData } from '~/mixins';

/**
 * Страница успешного запроса на изменение пароля
 */
@Component<Success>({
  metaInfo: {
    title: 'Password has been changed',
  },
})
export default class Success extends Mixins(UseLaravelData) {}
