
import type { AxiosError } from 'axios';
import { Component, Mixins } from 'vue-property-decorator';

import ModalError from '~/components/modal-error';
import UserSettings from '~/components/user-settings';
import { UseLaravelData } from '~/mixins';

/**
 * Настройки аккаунта
 */
@Component<Token>({
  components: { ModalError, UserSettings },

  created() {
    this.$apollo
      .mutate({
        mutation: this.$api.Settings.acceptAccountDeletion,
        variables: { token: this.$route.params.token },
      })
      .then(() => {
        window.location.pathname = '/account-has-been-deleted';

        return true;
      })
      .catch((error: AxiosError) => {
        this.error = this.$utils.get(error, 'networkError.result.message');
        this.isShowErrorDialog = true;
      });
  },

  metaInfo: {
    title: 'Settings',
  },
})
export default class Token extends Mixins(UseLaravelData) {
  /**
   * Текст ошибки (если есть)
   */
  error = '';

  /**
   * Отображение диалога с сообщением об ошибке
   */
  isShowErrorDialog = false;
}
