
import { Component, Mixins } from 'vue-property-decorator';

import TextContent from '~/content/aml-policy.md';
import { UseLaravelData } from '~/mixins';

/**
 * AmlPolicy
 */
@Component<AmlPolicy>({
  components: { TextContent },

  metaInfo: {
    title: 'Anti-Money Laundering Policy',
  },
})
export default class AmlPolicy extends Mixins(UseLaravelData) {}
