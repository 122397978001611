
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FormLogout from '~/components/form-logout';
import ServiceLink from '~/components/service-link';
import { UsePublic } from '~/mixins';

const Layout = namespace('layout');

/**
 * Менюшка аккаунта
 */
@Component({
  components: {
    FormLogout,
    ServiceLink,
  },
})
export default class AccountDropdown extends Mixins(UsePublic) {
  @Layout.Action('changeMenuState')
  readonly changeMenuState;

  /**
   * Принудительно отображать публичные пункты меню
   */
  @Prop({ default: false, type: Boolean })
  readonly forcePublicMenu!: boolean;

  @Layout.State('isMenuOpen')
  readonly isMenuOpen;

  /**
   * Скрытие меню при клике по его элементам
   */
  isCloseOnContentClick = true;
}
