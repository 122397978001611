import { SET } from '~/store/mutations';

export const namespaced = true;

/**
 * Хранилище данных форм
 */
interface FormsDataState {
  //
  /**
   * Форма логина
   */
  login: Record<string, any>;

  /**
   * Форма регистрации
   */
  register: Record<string, any>;
}

export const state = (): FormsDataState => ({
  login: {},
  register: {},
});

export const mutations = {
  SET_LOGIN: SET('login'),
  SET_REGISTER: SET('register'),
};

export const actions = {
  /**
   * Очищает стор
   *
   * @param context
   * @param context.commit
   */
  clearFormsData({ commit }) {
    Object.keys(mutations).forEach((mutation) => commit(mutation, {}));
  },

  /**
   * Записывает данные из формы логина
   *
   * @param context
   * @param context.commit
   * @param data
   */
  setLoginData({ commit }, data: FormsDataState['login']) {
    commit('SET_LOGIN', data);
  },

  /**
   * Записывает данные из формы регистрации
   *
   * @param context
   * @param context.commit
   * @param data
   */
  setRegisterData({ commit }, data: FormsDataState['register']) {
    commit('SET_REGISTER', data);
  },
};
