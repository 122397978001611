
import { Component, Mixins } from 'vue-property-decorator';

import TextContent from '~/content/privacy-policy.md';
import { UseLaravelData } from '~/mixins';

/**
 * PrivacyPolicy
 */
@Component<PrivacyPolicy>({
  components: { TextContent },

  metaInfo: {
    title: 'Privacy Policy',
  },
})
export default class PrivacyPolicy extends Mixins(UseLaravelData) {}
