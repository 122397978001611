
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Form2faCode from '~/components/form-2fa-code';

/**
 * Модалка с формой ввода 2FA
 */
@Component<Modal2faCode>({
  components: { Form2faCode },
})
export default class Modal2faCode extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;

  /**
   * При открытии модалки ставит фокус на инпуте
   *
   * @param newValue Состояние модалки
   */
  @Watch('value')
  onValueChange(newValue): void {
    if (newValue) (this.$refs.FORM_2FA_CODE as Form2faCode).setFocus();
  }

  /**
   * Сбрасывает форму
   */
  resetForm(): void {
    (this.$refs.FORM_2FA_CODE as Form2faCode).resetData();
  }
}
