import '@alfa/ui-kit/ui/assets/styles/index.scss';
import './assets/styles/index.scss';
import './plugins';
import 'vue-class-component/hooks';

import Vue from 'vue';

import App from './app.vue';
import vuetify from './plugins/alfa-ui-kit';
import apolloProvider from './plugins/apollo';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';

const LARAVEL_DATA_HTML_ELEMENT_ID = '#laravel-data';

const appConfig = {
  apolloProvider,

  data() {
    return {
      LARAVEL_CSRF_TOKEN: undefined,

      LARAVEL_DATA: {
        errors: {},
        serverErrorCode: undefined,
        session: '',
      },
    };
  },

  methods: {
    /**
     * При наличии серверной ошибки производит редирект на соответствующую страницу
     */
    checkServerError(): void {
      const dataNode: HTMLElement = document.querySelector(
        LARAVEL_DATA_HTML_ELEMENT_ID,
      );

      const errorCode = Number(dataNode?.dataset?.serverErrorCode);

      if (errorCode) {
        this.LARAVEL_DATA.serverErrorCode = errorCode;
      }
    },

    /**
     * Очищает данные Laravel
     *
     * Так как маршрутизация клиентская то в шаблоне остаются ошибки laravel.
     * Этот метод очищает шаблон от них и обновляет данные компонента.
     *
     * Используется страницами в хуке beforeRouteLeave
     */
    clearLaravelData(): void {
      const dataNode: HTMLElement = document.querySelector(
        LARAVEL_DATA_HTML_ELEMENT_ID,
      );

      try {
        dataNode.dataset.errors = '{}';
        dataNode.dataset.session = '';
        this.setLaravelData();
      } catch (error) {
        console.warn(error);
      }
    },

    /**
     * Записывает токен Laravel из blade шаблона в компонент для доступа к нему
     * через $root
     */
    setLaravelCsrfToken(): void {
      this.LARAVEL_CSRF_TOKEN =
        (document.querySelector('meta[name=csrf-token]') as any)?.content ||
        undefined;
    },

    /**
     * Записывает данные Laravel из blade шаблона в компонент для доступа к ним
     * через $root
     */
    setLaravelData(): void {
      const dataNode: HTMLElement = document.querySelector(
        LARAVEL_DATA_HTML_ELEMENT_ID,
      );

      /**
       * Записывает данные о сессии
       */
      const setSession = (): void => {
        const session = dataNode?.dataset?.session || '';

        this.LARAVEL_DATA.session = session;
      };

      /**
       * Записывает данные об ошибках
       */
      const setErrors = (): void => {
        try {
          const errors = JSON.parse(dataNode?.dataset?.errors || '{}');

          this.LARAVEL_DATA.errors = Array.isArray(errors) ? {} : errors;
        } catch (error) {
          console.warn(error);
        }
      };

      setSession();
      setErrors();
    },
  },

  mounted() {
    this.checkServerError();

    this.setLaravelCsrfToken();
    this.setLaravelData();
  },

  render: (h) => h(App),

  router,

  store,

  vuetify,
};

new Vue(appConfig).$mount('#app');
