import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Верификация
 */
export const getVerification = gql`
  query getVerification($level: String) {
    verification(level: $level)
      @rest(
        path: "/spa/verification?{args}"
        type: "Verification"
        method: "GET"
      ) {
      data
    }
  }
`;
