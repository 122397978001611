
import { Component, Mixins } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

import Modal2faCode from '~/components/modal-2fa-code';
import { Use2faCode, UseRecaptcha } from '~/mixins';

const FormsData = namespace('formsData');

/**
 * Форма логина
 */
@Component<FormLogin>({
  components: { Modal2faCode },

  created() {
    this._setDataFromStore();
  },

  validations: {
    formData: {
      mail: {
        email,
        required,
      },

      pass: {
        required,
      },
    },
  },
})
export default class FormLogin extends Mixins(UseRecaptcha, Use2faCode) {
  @FormsData.State('login')
  readonly loginData;

  @FormsData.Action('setLoginData')
  readonly setLoginData;

  /**
   * Данные формы
   */
  formData = {
    code: '',
    mail: '',
    pass: '',
  };

  /**
   * Запомнить пользователя
   */
  isRememberMe = false;

  /**
   * Отправка формы
   */
  submitForm(): void {
    this.setLoginData({
      ...this.formData,
      code: '',
    });

    (this.$refs.FORM as HTMLFormElement).submit();
  }

  /**
   * Устанавливает дефолтные данные из стора
   */
  private _setDataFromStore(): void {
    this.formData = { ...this.formData, ...this.loginData };
  }
}
