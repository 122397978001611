
import snsWebSdk from '@sumsub/websdk';
import { Component, Vue, Watch } from 'vue-property-decorator';

import ModalError from '~/components/modal-error';
import type { ACCOUNT_TYPES_FOR_UPGRADE } from '~/consts';

import UpgradeAccountLinks from './components/upgrade-account-links';

/**
 * Верификация аккаунта
 */
@Component<AccountVerification>({
  apollo: {
    user() {
      return {
        fetchPolicy: 'network-only',
        query: this.$api.User.getUser,

        update(data) {
          const newData = data.user.data;

          const { roles } = newData;

          if (roles.includes('premium')) {
            this.$log(
              'Пользователь уже имеет статус "Премиум", перенаправление...',
            );
            this.$router.push('/');
          }

          return newData;
        },
      };
    },

    verification() {
      return {
        error(error) {
          this.verificationError.showModal = true;

          this.verificationError.message = this.$utils.get(
            error,
            'networkError.result.message',
          );
        },

        fetchPolicy: 'network-only',
        query: this.$api.Verification.getVerification,

        // ожидает данных по уровню верификации
        skip() {
          return !this.widgetLevel;
        },

        update(data) {
          const { access_token: accessToken, level_name: levelName } =
            data.verification.data || {};

          return { accessToken, levelName };
        },

        variables() {
          return {
            level: this.$utils.upperFirst(this.widgetLevel),
          };
        },
      };
    },
  },

  components: { ModalError, UpgradeAccountLinks },

  created() {
    this.uid = `ID${this.$utils.nanoid()}`;
  },
})
export default class AccountVerification extends Vue {
  /**
   * Признак недоступности сервиса
   */
  isServiceUnavailable = false;

  /**
   * Идентификатор контейнера для монтирования виджета
   */
  uid = '';

  /**
   * Данные по пользователю
   */
  user: Record<string, any> = {
    mail: '',
    roles: [],
  };

  /**
   * Данные по верификации
   */
  verification: Record<string, any> = {
    accessToken: '',
    levelName: '',
  };

  /**
   * Данные по ошибке верификации
   */
  verificationError = {
    message: '',
    showModal: false,
  };

  /**
   * Отображение блока с кнопкой "обновить статус до премиум"
   */
  get isUpgradeLinksVisible() {
    const { roles } = this.user;
    const { levelName } = this.verification;

    return levelName === 'standard' && roles.includes('standard');
  }

  /**
   * Уровень виджета
   */
  get widgetLevel(): typeof ACCOUNT_TYPES_FOR_UPGRADE[number] | '' {
    const {
      registration_info: registrationInfo,
      roles,
      verification_info: verificationInfo,
    } = this.user;

    const countryType =
      verificationInfo?.country?.type || registrationInfo?.country?.type;

    if (roles.length === 0) return '';

    return roles.includes('standard')
      ? 'premium'
      : countryType === 'normal'
      ? 'premium'
      : 'standard';
  }

  /**
   * При получении токена монтирует виджет
   *
   * @param newValue
   * @param oldValue
   */
  @Watch('verification.accessToken')
  onTokenRequestEnd(newValue, oldValue) {
    if (!oldValue && newValue && !this.isServiceUnavailable) {
      this.mountWidget();
    }
  }

  /**
   * Монтирует виджет
   */
  async mountWidget() {
    const { mail: email } = this.user;

    const { accessToken } = this.verification;

    const widget = snsWebSdk
      .init(accessToken, async () => {
        await this.$apollo.queries.verification.refetch();

        const { accessToken: newAccessToken } = this.verification;

        return newAccessToken;
      })
      .withConf({ email, lang: 'en' })
      .withOptions({ adaptIframeHeight: true, addViewportTag: false })
      .on('idCheck.stepCompleted', (payload) => {
        this.$log('[SumSub:stepCompleted]', payload);
      })
      .on('idCheck.onError', (error) => {
        this.$log('[SumSub:onError]', error);
      })
      .onMessage((type, payload) => {
        this.$log('[SumSub:onMessage]', type, payload);

        if ((type as string) === 'idCheck.applicantStatus') {
          this.$apollo.queries.verification.refetch();
        }
      })
      .build();

    widget.launch(`#${this.uid}`);
  }
}
