
import { Component, Mixins } from 'vue-property-decorator';

import { UsePublic } from '~/mixins';

/**
 * Меню политик конфиденциальности
 */
@Component<MenuLegal>({})
export default class MenuLegal extends Mixins(UsePublic) {}
