
import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import TypeCode from '~/components/type-code';

/**
 * Форма ввода 2FA кода
 */
@Component<Form2faCode>({
  components: {
    TypeCode,
  },

  validations: {
    formData: {
      code: {
        required,
      },
    },
  },
})
export default class Form2faCode extends Vue {
  /**
   * Данные формы
   */
  formData = {
    code: '',
  };

  /**
   * Сброс и закрытие формы
   */
  onCancel(): void {
    this.$emit('cancel');
    this.resetData();
  }

  /**
   * Сброс данных
   */
  resetData(): void {
    this.formData.code = '';
  }

  /**
   * Устанавливает фокус на инпуте
   */
  setFocus() {
    setTimeout(() => {
      (this.$refs.TYPE_CODE as any).focus();
    }, 0);
  }
}
