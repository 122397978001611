
import { Component, Vue } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';

/**
 * Форма смены почтового адреса
 */
@Component<FormChangeEmail>({
  validations: {
    formData: {
      mail: {
        email,
        required,
      },

      pass: {
        required,
      },
    },
  },
})
export default class FormChangeEmail extends Vue {
  /**
   * Дефолтные данные формы
   */
  defaultFormData = {
    mail: '',
    pass: '',
  };

  /**
   * Ошибки серверной валидации
   */
  errors = {};

  /**
   * Данные формы
   */
  formData = {
    mail: '',
    pass: '',
  };

  /**
   * Отменяет отправку формы
   */
  onCancel() {
    this.resetValidation();
    this.formData = { ...this.defaultFormData };
    this.$emit('cancel');
  }

  /**
   * Сбрасывает валидацию
   */
  resetValidation() {
    this.errors = {};
    this.$v.$reset();
  }

  /**
   * Валидация данных
   */
  validate(): boolean {
    this.$v.$touch();

    return !this.$v.$invalid;
  }
}
