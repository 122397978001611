
import { Component, Mixins } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';

import Modal2faCode from '~/components/modal-2fa-code';
import { Use2faCode, UseRecaptcha } from '~/mixins';

/**
 * Форма восстановления пароля
 */
@Component<FormForgotPassword>({
  components: { Modal2faCode },

  validations: {
    formData: {
      mail: {
        email,
        required,
      },
    },
  },
})
export default class FormForgotPassword extends Mixins(
  UseRecaptcha,
  Use2faCode,
) {
  /**
   * Данные формы
   */
  formData = {
    code: '',
    mail: '',
  };

  /**
   * Отправка формы
   */
  submitForm(): void {
    (this.$refs.FORM as HTMLFormElement).submit();
  }
}
