
import { Component, Mixins } from 'vue-property-decorator';

import TextContent from '~/content/verification-levels-explained.md';
import { UseLaravelData } from '~/mixins';

/**
 * VerificationLevelsExplained
 */
@Component<VerificationLevelsExplained>({
  components: { TextContent },

  metaInfo: {
    title: 'Verification Levels Explained',
  },
})
export default class VerificationLevelsExplained extends Mixins(
  UseLaravelData,
) {}
