
import { Component, Mixins } from 'vue-property-decorator';

import { UseEnvironment, UseLaravelData } from '~/mixins';

/**
 * Страница ошибки 401
 */
@Component<Page>({
  metaInfo: {
    title: '401',
  },
})
export default class Page extends Mixins(UseLaravelData, UseEnvironment) {}
