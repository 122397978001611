/**
 * Убирает протокол из адреса
 *
 * @example
 *   removeProtocolFromUrl('http://ya.ru') => 'ya.ru'
 *
 * @param url Адрес
 */
export function removeProtocolFromUrl(url: string): string {
  return url.replace(/https?:\/\//, '');
}
