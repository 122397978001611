
import { Component, Vue } from 'vue-property-decorator';

import ModalDeleteAccount from '~/components/modal-delete-account';

/**
 * Удаление аккаунта
 */
@Component<PanelDeleteAccount>({
  components: {
    ModalDeleteAccount,
  },
})
export default class PanelDeleteAccount extends Vue {
  /**
   * Отображение модалки удаления аккаунта
   */
  isShowDeleteAccountForm = false;
}
