import type { RouteConfig } from 'vue-router';

export const signUpLayoutRoutes: RouteConfig[] = [
  {
    children: [
      {
        component: () => import('~/pages/country-is-not-supported/index.vue'),
        name: 'country-is-not-supported',
        path: '/country-is-not-supported',
      },
      {
        component: () => import('~/pages/email/verify/index.vue'),
        name: 'email-verify',
        path: '/email/verify',
      },
      {
        component: () => import('~/pages/register/index.vue'),
        name: 'register',
        path: '/register',
      },
      {
        component: () => import('~/pages/verify-your-account/index.vue'),
        name: 'verify-your-account',
        path: '/verify-your-account',
      },
    ],

    component: () => import('~/layouts/sign-up.vue'),
    path: '/login',
  },
];
