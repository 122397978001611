
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ServiceLink from '~/components/service-link';

const Layout = namespace('layout');

/**
 * Менюшка сервисов
 */
@Component<ServicesDropdown>({
  components: { ServiceLink },
})
export default class ServicesDropdown extends Vue {
  @Layout.Action('changeMenuState')
  readonly changeMenuState;
}
