import mitt from 'mitt';
import Vue from 'vue';

const emitter = mitt();

Vue.prototype.$eventBus = emitter;

declare module 'vue/types/vue' {
  interface Vue {
    //
    /**
     * Шина событий
     */
    $eventBus: typeof emitter;
  }
}
