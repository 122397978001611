import type { RouteConfig } from 'vue-router';

export const loginLayoutRoutes: RouteConfig[] = [
  {
    children: [
      {
        component: () => import('~/pages/login/index.vue'),
        name: 'login',
        path: '',
      },
      {
        component: () => import('~/pages/password/reset/index.vue'),
        name: 'password-reset',
        path: '/password/reset',
      },
      {
        component: () => import('~/pages/password/reset/success.vue'),
        name: 'password-reset-success',
        path: '/password/reset/success',
      },
      {
        component: () => import('~/pages/password/reset/_token.vue'),
        name: 'password-reset-token',
        path: '/password/reset/:token',
      },
      {
        component: () => import('~/pages/account-has-been-deleted/index.vue'),
        name: 'account-has-been-deleted',
        path: '/account-has-been-deleted',
      },
    ],

    component: () => import('~/layouts/login.vue'),
    path: '/login',
  },
];
