import Vue from 'vue';
import Vuex from 'vuex';

import * as modules from './modules';
import { PersistedStatePlugin } from './plugins';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,

  plugins: [PersistedStatePlugin],

  strict: process.env.NODE_ENV === 'development',
});
