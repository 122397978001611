
import { Component, Mixins } from 'vue-property-decorator';

import UserSettings from '~/components/user-settings';
import { UseLaravelData } from '~/mixins';

/**
 * Настройки аккаунта
 */
@Component<Settings>({
  components: { UserSettings },

  metaInfo: {
    title: 'Settings',
  },
})
export default class Settings extends Mixins(UseLaravelData) {}
