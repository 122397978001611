var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":_vm.$api.User.getUser,"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var userData = ref.result.data;
    var isGetUserQueryLoading = ref.isLoading;
return [_c('form',{staticClass:"form-change-password",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();_vm.submitFormWith2faCheck(_vm.$utils.get(userData, 'user.data.mail'))}}},[_c('p',{staticClass:"form-change-password__title"},[_vm._v("Change Password")]),_c('span',{staticClass:"form-change-password__subtitle"},[_vm._v(" Enter your current password and then confirm a new one ")]),_c('v-expand-transition',[(_vm.errors.message)?_c('ui-alert',{staticClass:"[ mb-2 ]",attrs:{"color":"danger","icon":"ri-error-warning-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.errors.message))]},proxy:true}],null,true)}):_vm._e()],1),_c('v-expand-transition',[(_vm.errors.code)?_c('ui-alert',{staticClass:"[ mb-2 ]",attrs:{"color":"danger","icon":"ri-error-warning-line"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.errors.code[0]))]},proxy:true}],null,true)}):_vm._e()],1),_c('div',{staticClass:"form-change-password__fieldset"},[_c('ui-input',{attrs:{"invalid":_vm.$v.formData.pass.$error || _vm.errors.pass,"name":"new_pass","placeholder":"Enter your current password","size":"large","type":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Current password ")]},proxy:true},{key:"rules",fn:function(){return [(_vm.$v.formData.pass.$error || _vm.errors.pass)?_c('ui-rules-list',[_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.pass.$error && !_vm.$v.formData.pass.minLength
                    ? 'danger'
                    : _vm.$v.formData.pass.minLength
                    ? 'success'
                    : null}},[_vm._v(" 10 characters minimum ")]),_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.pass.$error && !_vm.$v.formData.pass.hasUpperCase
                    ? 'danger'
                    : _vm.$v.formData.pass.hasUpperCase
                    ? 'success'
                    : null}},[_vm._v(" UPPER CASE letters ")]),_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.pass.$error && !_vm.$v.formData.pass.hasNumber
                    ? 'danger'
                    : _vm.$v.formData.pass.hasNumber
                    ? 'success'
                    : null}},[_vm._v(" Numbers ")]),_vm._l((_vm.errors.pass),function(error){return _c('ui-rules-list-item',{key:error,attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(error)+" ")])})],2):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.formData.pass),callback:function ($$v) {_vm.$set(_vm.formData, "pass", $$v)},expression:"formData.pass"}}),_c('ui-input',{attrs:{"invalid":_vm.$v.formData.new_pass.$error || _vm.errors.new_pass,"name":"new_pas","placeholder":"Enter new password","size":"large","type":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Choose a new password ")]},proxy:true},{key:"rules",fn:function(){return [_c('ui-rules-list',[_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.new_pass.$error &&
                  !_vm.$v.formData.new_pass.minLength
                    ? 'danger'
                    : _vm.$v.formData.new_pass.minLength
                    ? 'success'
                    : null}},[_vm._v(" 10 characters minimum ")]),_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.new_pass.$error &&
                  !_vm.$v.formData.new_pass.hasUpperCase
                    ? 'danger'
                    : _vm.$v.formData.new_pass.hasUpperCase
                    ? 'success'
                    : null}},[_vm._v(" UPPER CASE letters ")]),_c('ui-rules-list-item',{attrs:{"color":_vm.$v.formData.new_pass.$error &&
                  !_vm.$v.formData.new_pass.hasNumber
                    ? 'danger'
                    : _vm.$v.formData.new_pass.hasNumber
                    ? 'success'
                    : null}},[_vm._v(" Numbers ")]),_vm._l((_vm.errors.new_pass),function(error){return _c('ui-rules-list-item',{key:error,attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(error)+" ")])})],2)]},proxy:true}],null,true),model:{value:(_vm.formData.new_pass),callback:function ($$v) {_vm.$set(_vm.formData, "new_pass", $$v)},expression:"formData.new_pass"}}),_c('ui-input',{attrs:{"invalid":_vm.$v.formData.new_pass_confirmation.$error ||
            _vm.errors.new_pass_confirmation,"name":"new_pass","placeholder":"Confirm new password","size":"large","type":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm password ")]},proxy:true},{key:"rules",fn:function(){return [(
                _vm.$v.formData.new_pass_confirmation.$error ||
                _vm.errors.new_pass_confirmation
              )?_c('ui-rules-list',[(!_vm.$v.formData.new_pass_confirmation.required)?_c('ui-rules-list-item',{attrs:{"color":"danger"}},[_vm._v(" Required ")]):_vm._e(),(!_vm.$v.formData.new_pass_confirmation.sameAs)?_c('ui-rules-list-item',{attrs:{"color":"danger"}},[_vm._v(" Passwords must match ")]):_vm._e(),_vm._l((_vm.errors.new_pass_confirmation),function(error){return _c('ui-rules-list-item',{key:error,attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(error)+" ")])})],2):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.formData.new_pass_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "new_pass_confirmation", $$v)},expression:"formData.new_pass_confirmation"}})],1),_c('nav',{staticClass:"form-change-password__nav"},[_c('ui-a',{staticStyle:{"font-size":"20px"},attrs:{"tag":"button","type":"button"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" Cancel ")]),_c('ui-button',{attrs:{"color":"success","disabled":_vm.isLoading || Boolean(isGetUserQueryLoading),"size":"giant","type":"button"},on:{"click":function($event){_vm.submitFormWith2faCheck(_vm.$utils.get(userData, 'user.data.mail'))}}},[_vm._v(" Change password ")])],1),_c('modal-2fa-code',{ref:"MODAL_2FA_CODE",attrs:{"persistent":""},on:{"done":function (code) {
            _vm.isShow2faForm = false;
            _vm.formData.code = code;
            _vm.submitForm();
          }},model:{value:(_vm.isShow2faForm),callback:function ($$v) {_vm.isShow2faForm=$$v},expression:"isShow2faForm"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }