var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel',{staticClass:"carousel-sign-up",attrs:{"cycle":"","delimiter-icon":"$ri-checkbox-blank-circle-fill","height":"100%","hide-delimiters":"","interval":4000,"show-arrows":false},model:{value:(_vm.activeSlideIndex),callback:function ($$v) {_vm.activeSlideIndex=$$v},expression:"activeSlideIndex"}},[_vm._l((_vm.slides),function(slide){return _c('v-carousel-item',{key:slide.text,staticClass:"carousel-sign-up__slide",attrs:{"eager":"","reverse-transition":"scroll-x-reverse-transition","transition":"scroll-x-transition"}},[_c('div',{staticClass:"carousel-sign-up__slide-content-wrap"},[_c('v-img',{staticClass:"carousel-sign-up__slide-image",attrs:{"alt":"logo","eager":"","src":slide.image},on:{"load":function($event){_vm.isSlideImageLoaded = true}}}),_c('v-fade-transition',[(_vm.isSlideImageLoaded)?_c('article',{staticClass:"carousel-sign-up__slide-text"},[_vm._v(" "+_vm._s(slide.text)+" ")]):_vm._e()])],1)])}),_c('div',{staticClass:"carousel-sign-up__overlay-grid"},[_c('a',{staticClass:"carousel-sign-up__logo-link",attrs:{"href":"/"}},[_c('v-img',{staticClass:"carousel-sign-up__logo",attrs:{"alt":"Alfa Account logo","height":"36px","src":require('./images/alfacash-accounts-logo.svg'),"width":"214px"}}),_vm._v(" Link to home page ")],1),_c('nav',{staticClass:"carousel-sign-up__navigation"},_vm._l((_vm.slides),function(item,index){return _c('button',{key:item.text,class:{
          'carousel-sign-up__navigation-dot': true,
          'carousel-sign-up__navigation-dot--active':
            _vm.activeSlideIndex === index,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.activeSlideIndex = index}}},[_c('span',{class:{
            'carousel-sign-up__navigation-dot-decoration': true,
            'carousel-sign-up__navigation-dot-decoration--active':
              _vm.activeSlideIndex === index,
          }})])}),0),(!_vm.$mq.includes('laptop'))?_c('ui-button',{staticClass:"carousel-sign-up__skip-button",attrs:{"block":"","size":"giant"},on:{"click":function($event){return _vm.$emit('skip')}}},[_vm._v(" Skip ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }