import { Component, Vue } from 'vue-property-decorator';

/**
 * Использование данных из blade-шаблона Laravel
 */
@Component<UseLaravelData>({
  beforeRouteLeave(to, from, next) {
    if (from.path !== to.path) {
      (this.$root as any).clearLaravelData();
    }

    next();
  },
})
export default class UseLaravelData extends Vue {}
