import type { RouteConfig } from 'vue-router';

export const errorLayoutRoutes: RouteConfig[] = [
  {
    children: [
      {
        component: () => import('~/pages/error/401.vue'),
        name: 'error-401',
        path: '401',
      },
      {
        component: () => import('~/pages/error/403.vue'),
        name: 'error-403',
        path: '403',
      },
      {
        component: () => import('~/pages/error/404.vue'),
        name: 'error-404',
        path: '404',
      },
      {
        component: () => import('~/pages/error/419.vue'),
        name: 'error-419',
        path: '419',
      },
      {
        component: () => import('~/pages/error/429.vue'),
        name: 'error-429',
        path: '429',
      },
      {
        component: () => import('~/pages/error/500.vue'),
        name: 'error-500',
        path: '500',
      },
      {
        component: () => import('~/pages/error/503.vue'),
        name: 'error-503',
        path: '503',
      },
    ],

    component: () => import('~/layouts/error.vue'),
    path: '/error',
  },
];
