import { render, staticRenderFns } from "./modal-2fa-disabled.component.vue?vue&type=template&id=42e8f0f0&scoped=true&"
import script from "./modal-2fa-disabled.component.vue?vue&type=script&lang=ts&"
export * from "./modal-2fa-disabled.component.vue?vue&type=script&lang=ts&"
import style0 from "./modal-2fa-disabled.component.vue?vue&type=style&index=0&id=42e8f0f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42e8f0f0",
  null
  
)

export default component.exports