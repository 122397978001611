import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Включение 2FA
 *
 * @param input.code {string} Код из приложения Google
 */
export const enable2fa = gql`
  mutation enable2fa($input: Any) {
    enable2fa(input: $input)
      @rest(
        path: "/spa/settings/2fa/enable"
        type: "Enable2Fa"
        method: "POST"
      ) {
      data
      errors
      message
    }
  }
`;
