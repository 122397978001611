
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AppHeader from '~/components/app-header';
import CarouselSignUp from '~/components/carousel-sign-up';
import CookieAlertBanner from '~/components/cookie-alert-banner';

const Layout = namespace('layout');

/**
 * Шаблон для страниц входа/регистрации
 */
@Component<SignUp>({
  components: { AppHeader, CarouselSignUp, CookieAlertBanner },
})
export default class SignUp extends Vue {
  @Layout.Action('changeSignUpSliderVisibility')
  readonly changeSignUpSliderVisibility;

  @Layout.State('isSignUpSliderVisibleOnSmallScreens')
  readonly isSignUpSliderVisibleOnSmallScreens;
}
