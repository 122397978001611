
import { Component, Mixins } from 'vue-property-decorator';

import FormForgotPassword from '~/components/form-forgot-password';
import { UseLaravelData } from '~/mixins';

/**
 * Страница запроса на сброс пароля
 */
@Component<Reset>({
  components: {
    FormForgotPassword,
  },

  metaInfo: {
    title: 'Forgot password',
  },
})
export default class Reset extends Mixins(UseLaravelData) {}
