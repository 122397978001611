import { Component, Vue } from 'vue-property-decorator';

/**
 * Использование проверки на обязательный ввод 2FA кода
 */
@Component<Use2faCode>({})
export default class Use2faCode extends Vue {
  /**
   * Отображение формы 2FA
   */
  isShow2faForm = false;

  /**
   * Проверяет включена ли 2FA
   *
   * @example
   *   ```ts
   *   check2faStatus("ivan.mail.ru") => true
   *   ```;
   *
   * @param mail Почта пользователя
   *
   * @returns Если истина то 2FA обязателен
   */
  async check2faStatus(mail: string): Promise<boolean> {
    return await this.$apollo
      .query({
        fetchPolicy: 'network-only',
        query: this.$api.Settings.check2faStatus,
        variables: { input: { mail } },
      })
      .then((response) => {
        const is2faEnable: boolean = this.$utils.get(
          response,
          'data.twoFaStatus.data.2fa',
        );

        if (is2faEnable) {
          this.isShow2faForm = true;

          return true;
        }

        return false;
      })
      .catch((error) => {
        console.error(error);

        return false;
      });
  }
}
