
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Модалка с сообщением об успешной активации 2FA
 */
@Component<Modal2faEnabled>({})
export default class Modal2faEnabled extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;
}
