var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"account-dropdown",attrs:{"close-on-content-click":_vm.isCloseOnContentClick,"content-class":"account-dropdown__content","left":"","min-width":144,"offset-y":"","transition":"slide-y-transition"},on:{"input":function (value) { return _vm.changeMenuState(value); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"account-dropdown__button",attrs:{"type":"button"}},'button',attrs,false),on),[_c('div',{staticClass:"account-dropdown__wrapper"},[_c('v-icon',{staticClass:"account-dropdown__icon",attrs:{"size":"1rem"}},[_vm._v(" $ri-account-circle-fill ")]),_vm._v(" Account "),_c('v-icon',{staticClass:"account-dropdown__suffix-icon",attrs:{"color":"secondary","size":"1.2rem"}},[_vm._v(" $ri-arrow-drop-down-line ")])],1),_c('v-icon',{staticClass:"account-dropdown__menu-icon",attrs:{"color":"white","size":"24px"}},[_vm._v(" $"+_vm._s(_vm.isMenuOpen ? 'ri-close-line' : 'ri-menu-line')+" ")])],1)]}}])},[(_vm.isRoutePublic || _vm.forcePublicMenu)?_c('nav',{staticClass:"account-dropdown__content account-dropdown__content--public"},[_c('button',{class:{
        'account-dropdown__item': true,
        'account-dropdown__item--active': _vm.$route.name === 'login',
      },attrs:{"type":"button"},on:{"click":function () {
          _vm.isCloseOnContentClick = true;
          _vm.$router.push({ name: 'login' });
        }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-login-box-line ")]),_vm._v(" Log in ")],1),_c('button',{class:{
        'account-dropdown__item': true,
        'account-dropdown__item--active': _vm.$route.name === 'register',
      },attrs:{"type":"button"},on:{"click":function () {
          _vm.isCloseOnContentClick = true;
          _vm.$router.push({ name: 'register' });
        }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-file-user-line ")]),_vm._v(" Sign up ")],1)]):_c('nav',{staticClass:"account-dropdown__content"},[_c('button',{class:{
        'account-dropdown__item': true,
        'account-dropdown__item--active': _vm.$route.name === 'index',
      },attrs:{"type":"button"},on:{"click":function () {
          _vm.isCloseOnContentClick = true;
          _vm.$router.push({ name: 'index' });
        }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-account-circle-fill ")]),_vm._v(" My profile ")],1),_c('button',{class:{
        'account-dropdown__item': true,
        'account-dropdown__item--active': _vm.$route.name === 'settings',
      },attrs:{"type":"button"},on:{"click":function () {
          _vm.isCloseOnContentClick = true;
          _vm.$router.push({ name: 'settings' });
        }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-settings-3-fill ")]),_vm._v(" Settings ")],1),_c('apollo-query',{attrs:{"query":_vm.$api.User.getUser,"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var userData = ref.result.data;
        var isLoading = ref.isLoading;
return [(
            !_vm.$utils
              .get(userData, 'user.data.roles', [])
              .includes('premium') && !isLoading
          )?_c('button',{class:{
            'account-dropdown__item': true,
            'account-dropdown__item--active': _vm.$route.name === 'verification',
          },attrs:{"type":"button"},on:{"click":function () {
              _vm.isCloseOnContentClick = true;
              _vm.$router.push({ name: 'verification' });
            }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-shield-flash-fill ")]),_vm._v(" Verification ")],1):_vm._e()]}}])}),(_vm.$mq.includes('tablet') || _vm.$mq.includes('mobile'))?_c('v-expansion-panels',{staticClass:"account-dropdown__expansion-panels",attrs:{"accordion":"","flat":""},nativeOn:{"click":function($event){return (function () { return (_vm.isCloseOnContentClick = false); }).apply(null, arguments)}}},[_c('v-expansion-panel',{staticClass:"account-dropdown__expansion-panel"},[_c('v-expansion-panel-header',{staticClass:"account-dropdown__expansion-panel-header"},[_c('span',{staticClass:"account-dropdown__expansion-panel-header-text"},[_c('v-icon',{staticStyle:{"margin-right":"24px"},attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-bookmark-fill ")]),_vm._v(" Services ")],1)]),_c('v-expansion-panel-content',{staticClass:"account-dropdown__expansion-panel-content"},[_c('service-link',{staticClass:"account-dropdown__service-link",attrs:{"href":"https://store.alfa.cash","icon":"ri-store-2-line","target":"_blank"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" Buy and sell Bitcoin, Ethereum, Tether, Litecoin, XRP, EOS and other cryptocurrencies with SEPA (EU) bank transfer instantly ")]},proxy:true}],null,false,2167763844)},[_vm._v(" Alfacash | Store ")])],1)],1)],1):_vm._e(),_c('button',{staticClass:"account-dropdown__item account-dropdown__item--logout",attrs:{"type":"button"},on:{"click":function () {
          _vm.isCloseOnContentClick = true;
          _vm.$refs.LOGOUT.submit();
        }}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" $ri-login-box-line ")]),_vm._v(" Log out "),_c('form-logout',{ref:"LOGOUT"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }