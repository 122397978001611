
import { Component, Mixins } from 'vue-property-decorator';
import { email, required, sameAs } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

import { UseRecaptcha } from '~/mixins';
import { nanoid } from '~/utils';

const FormsData = namespace('formsData');

/**
 * Форма регистрации
 */
@Component<FormRegister>({
  async created() {
    this.uid = `id-${nanoid()}`;

    this._setDefaultFormData();
  },

  /* eslint-disable @typescript-eslint/naming-convention */
  validations: {
    formData: {
      country: {
        required,
      },

      email: {
        email,
        required,
      },

      password: {
        hasNumber(value) {
          return /\d+/.test(value);
        },

        hasUpperCase(value) {
          return /[A-Z]+/.test(value);
        },

        minLength(value) {
          return value && value.length >= 10;
        },

        required,
      },

      password_confirmation: {
        required,
        sameAs: sameAs('password'),
      },
    },
    /* eslint-enable @typescript-eslint/naming-convention */
  },
})
export default class FormRegister extends Mixins(UseRecaptcha) {
  @FormsData.State('register')
  readonly registerData;

  @FormsData.Action('setRegisterData')
  readonly setRegisterData;

  aaa = '';

  /**
   * Согласие с политикой конфиденциальности
   */
  acceptPolicy = false;

  /**
   * Данные формы
   */
  /* eslint-disable @typescript-eslint/naming-convention */
  formData = {
    country: '',
    email: '',
    password: '',
    password_confirmation: '',
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * Было ли событие onblur на инпуте email
   */
  isEmailFieldWasBlured = false;

  /**
   * Уникальный идентификатор
   */
  uid!: string;

  /**
   * Определяет цвет суффиксной иконки инпута
   *
   * @param fieldName Имя поля формы
   */
  getSuffixIconColor(fieldName: keyof FormRegister['formData']) {
    const defaultColor =
      this.$v.formData[fieldName].$invalid &&
      this.$v.formData[fieldName].$error
        ? 'var(--ui-color-danger)'
        : !this.$v.formData[fieldName].$invalid &&
          !this.$v.formData[fieldName].$error
        ? 'var(--ui-color-success)'
        : undefined;

    if (fieldName === 'email') {
      return this.$v.formData.email.$invalid && this.isEmailFieldWasBlured
        ? 'var(--ui-color-danger)'
        : defaultColor;
    }

    return defaultColor;
  }

  /**
   * Определяет имя суффиксной иконки инпута
   *
   * @param fieldName Имя поля формы
   */
  getSuffixIconName(fieldName: keyof FormRegister['formData']) {
    const defaultIcon =
      this.$v.formData[fieldName].$invalid &&
      this.$v.formData[fieldName].$error
        ? 'ri-error-warning-fill'
        : !this.$v.formData[fieldName].$invalid &&
          !this.$v.formData[fieldName].$error
        ? 'ri-checkbox-circle-fill'
        : undefined;

    if (fieldName === 'email') {
      return this.$v.formData.email.$invalid && this.isEmailFieldWasBlured
        ? 'ri-error-warning-fill'
        : defaultIcon;
    }

    return defaultIcon;
  }

  /**
   * Сортирует страны по названию
   *
   * @param data Данные с сервера
   */
  sortCountries(data) {
    const newData = this.$utils.cloneDeep(data);

    if (newData?.countries?.data?.countries) {
      newData.countries.data.countries = this.$utils.sortBy(
        data.countries.data.countries,
        ['name'],
      );
    }

    return newData;
  }

  /**
   * Отправка формы
   */
  submitForm(): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    this.setRegisterData({
      ...this.formData,
      password: '',
      password_confirmation: '',
    });
    /* eslint-enable @typescript-eslint/naming-convention */

    (this.$refs.FORM as HTMLFormElement).submit();
  }

  /**
   * Устанавливает дефолтные данные из стора
   */
  private async _setDefaultFormData() {
    // записывает публичные данные, полученные ранее из других форм (типа почты и имени пользователя)
    this.formData = { ...this.formData, ...this.registerData };
    // подставляет страну пользователя)
    this.formData.country = await this.$utils.getClientCountry();
  }
}
