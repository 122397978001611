
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Модалка с сообщением об успешной деактивации 2FA
 */
@Component<Modal2faDisabled>({})
export default class Modal2faDisabled extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;
}
