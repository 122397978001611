
import { Component, Vue } from 'vue-property-decorator';

import AppFooter from '~/components/app-footer';
import AppHeader from '~/components/app-header';

/**
 * Шаблон страницы с ошибкой
 */
@Component<Error>({
  components: {
    AppFooter,
    AppHeader,
  },
})
export default class Error extends Vue {}
