import { Component, Vue } from 'vue-property-decorator';

/**
 * Миксин для доступа к некоторым переменным окружения
 */
@Component<UseEnvironment>({})
export default class UseEnvironment extends Vue {
  /**
   * Публичный путь
   *
   * @see [html-and-static-assets](https://cli.vuejs.org/ru/guide/html-and-static-assets.html#%D0%BA%D0%B0%D1%82%D0%B0n%D0%BE%D0%B3-public)
   */
  publicPath = process.env.BASE_URL;
}
