/**
 * Узнает страну клиента
 */
export async function getClientCountry(): Promise<string> {
  const traceResult = await fetch(
    `${
      !process.env.VUE_APP_TRACE_HOST ||
      process.env.VUE_APP_TRACE_HOST === 'undefined'
        ? ''
        : process.env.VUE_APP_TRACE_HOST
    }/cdn-cgi/trace`,
  )
    .then((data) => data.text())
    .catch(() => '');

  if (traceResult) {
    let data = traceResult.replace(/[\n\r]+/g, '","').replace(/=+/g, '":"');

    data = `{"${data.slice(0, data.lastIndexOf('","'))}"}`;

    return JSON.parse(data)?.loc || '';
  }

  return '';
}
