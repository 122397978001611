
/* eslint-disable global-require */
import { Component, Vue } from 'vue-property-decorator';

/**
 * Карусель на странице регистрации
 */
@Component<CarouselSignUp>({})
export default class CarouselSignUp extends Vue {
  /**
   * Индекс активного слайда
   */
  activeSlideIndex = 0;

  /**
   * Было ли загружено изображение слайда
   */
  isSlideImageLoaded = false;

  /**
   * Коллекция слайдов
   */
  slides = [
    {
      image: require('./images/01-img-asset@3x.png'),

      text:
        'Registration has never been so easy - a one-stop shop for all Alfacash services',
    },
    {
      image: require('./images/02-img-asset@3x.png'),

      text: 'Verify your account to increase transaction and monthly limits',
    },
    {
      image: require('./images/03-img-asset@3x.png'),

      text: 'Top-of-the-line security and round-the-clock support',
    },
    {
      image: require('./images/04-img-asset@3x.png'),

      text:
        'Fully automated verification, user-friendly platform and strong protection of customer data',
    },
  ];
}
