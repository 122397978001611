
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Модалка с сообщением об ошибке при удалении аккаунта
 */
@Component<ModalError>({})
export default class ModalError extends Vue {
  /**
   * Сообщение об ошибке
   */
  @Prop({ type: String })
  readonly error!: string;

  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;
}
