
import { Component, Vue } from 'vue-property-decorator';

import ModalChangeEmail from '~/components/modal-change-email';

/**
 * Смена почтового адреса
 */
@Component<PanelChangeEmail>({
  components: {
    ModalChangeEmail,
  },
})
export default class PanelChangeEmail extends Vue {
  /**
   * Отображение модалки замены почтового адреса
   */
  isShowChangeEmailModal = false;
}
