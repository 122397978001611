
import { Component, Vue } from 'vue-property-decorator';

import ModalChangePassword from '~/components/modal-change-password';

/**
 * Смена пароля
 */
@Component<PanelChangePassword>({
  components: {
    ModalChangePassword,
  },
})
export default class PanelChangePassword extends Vue {
  /**
   * Отображение модалки смены пароля
   */
  isShowChangePasswordModal = false;
}
