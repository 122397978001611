import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Страны
 */
export const getCountries = gql`
  query getCountries {
    countries @rest(path: "/spa/countries", type: "Countries", method: "GET") {
      data
    }
  }
`;
