import { render, staticRenderFns } from "./account-verification.component.vue?vue&type=template&id=4fb4f058&scoped=true&"
import script from "./account-verification.component.vue?vue&type=script&lang=ts&"
export * from "./account-verification.component.vue?vue&type=script&lang=ts&"
import style0 from "./account-verification.component.vue?vue&type=style&index=0&id=4fb4f058&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb4f058",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VFadeTransition,VOverlay,VProgressCircular,VSkeletonLoader})
