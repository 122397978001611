import { Component, Vue } from 'vue-property-decorator';

/**
 * Миксин для фильтра публичных маршрутов
 */
@Component<UsePublic>({
  created() {
    this.isRoutePublic = this.checkIsRoutePublic();
  },
})
export default class UsePublic extends Vue {
  /**
   * Если истина то маршрут является публичным
   */
  isRoutePublic = false;

  /**
   * Проверяет маршрут на публичность
   */
  checkIsRoutePublic(): boolean {
    return /^\/(?:public|login|register|error|country-is-not-supported)/.test(
      this.$route.path,
    );
  }
}
