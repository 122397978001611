var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"menu-legal-mobile"},[_c('p',{staticClass:"menu-legal-mobile__label"},[_vm._v("Choose document")]),_c('v-select',{staticClass:"menu-legal-mobile__select",attrs:{"attach":".menu-legal-mobile","items":[
      {
        text: 'Privacy Policy',
        value: 'privacy-policy',
      },
      {
        text: 'Cookie Policy',
        value: 'cookie-policy',
      },
      {
        text: 'AML Policy',
        value: 'aml-policy',
      },
      {
        text: 'Verification Levels Explained',
        value: 'verification-levels-explained',
      } ],"menu-props":{
      bottom: true,
      offsetY: true,
      nudgeBottom: 30,
      transition: 'slide-y-reverse-transition',
    },"outlined":""},on:{"input":function (value) { return _vm.$router.push({
          name: ((!_vm.isRoutePublic ? 'legal' : 'public') + "-" + value),
        }); }},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }