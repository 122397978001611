import type { ACCOUNT_TYPES_FOR_UPGRADE } from '~/consts';

/**
 * Узнает доступный тип аккаунта для апгрейда
 *
 * @param rolesArray Список ролей
 * @param countryType Тип страны указанный при регистрации
 */
export function getAvailableUpgradeType(
  rolesArray: string[] = [],
  countryType: string = '',
): typeof ACCOUNT_TYPES_FOR_UPGRADE[number] | '' {
  if (!rolesArray.includes('standard') && !rolesArray.includes('premium')) {
    return countryType === 'normal' ? 'premium' : 'standard';
  }

  if (rolesArray.includes('standard')) {
    return 'premium';
  }

  return '';
}
