/**
 * @param exponentialNumber
 */
export function convertExponentialToDecimal(
  exponentialNumber: number,
): number | string {
  const string = exponentialNumber.toString();

  if (string.includes('e')) {
    const exponent = Number.parseInt(string.split('-')[1], 10);

    return exponentialNumber.toFixed(exponent);
  }

  return exponentialNumber;
}
