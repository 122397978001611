import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Изменение пароля
 */
export const setSettingsPassword = gql`
  mutation setSettingsPassword($input: Any) {
    setSettingsPassword(input: $input)
      @rest(
        path: "/spa/settings/changePassword"
        type: "SetSettingsPassword"
        method: "POST"
      ) {
      status
      errors
      message
    }
  }
`;
