/* eslint-disable sonarjs/no-duplicate-string */
import type { RouteConfig } from 'vue-router';

export const defaultLayoutRoutes: RouteConfig[] = [
  {
    children: [
      {
        components: {
          default: () => import('~/pages/index.vue'),
          sidebar: () => import('~/components/menu-account'),
        },

        meta: { icon: 'ri-account-circle-fill' },
        name: 'index',
        path: '',
      },
      {
        components: {
          default: () => import('~/pages/settings/index.vue'),
          sidebar: () => import('~/components/menu-account'),
        },

        meta: { icon: 'ri-settings-3-fill' },
        name: 'settings',
        path: '/settings',
      },
      {
        components: {
          default: () => import('~/pages/settings/delete-account/_token.vue'),
          sidebar: () => import('~/components/menu-account'),
        },

        meta: { icon: 'ri-settings-3-fill' },
        name: 'settings-delete-account-token',
        path: '/settings/delete-account/:token',
      },
      {
        components: {
          default: () => import('~/pages/verification/index.vue'),
          sidebar: () => import('~/components/menu-account'),
        },

        meta: { icon: 'ri-shield-flash-fill' },
        name: 'verification',
        path: '/verification',
      },
      {
        components: {
          default: () => import('~/pages/legal/privacy-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'legal-privacy-policy',
        path: '/legal/privacy-policy',
      },
      {
        components: {
          default: () => import('~/pages/legal/cookie-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'legal-cookie-policy',
        path: '/legal/cookie-policy',
      },
      {
        components: {
          default: () => import('~/pages/legal/aml-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'legal-aml-policy',
        path: '/legal/aml-policy',
      },
      {
        components: {
          default: () =>
            import('~/pages/legal/verification-levels-explained.vue'),

          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'legal-verification-levels-explained',
        path: '/legal/verification-levels-explained',
      },
      {
        components: {
          default: () => import('~/pages/public/privacy-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'public-privacy-policy',
        path: '/public/privacy-policy',
      },
      {
        components: {
          default: () => import('~/pages/public/cookie-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'public-cookie-policy',
        path: '/public/cookie-policy',
      },
      {
        components: {
          default: () => import('~/pages/public/aml-policy.vue'),
          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'public-aml-policy',
        path: '/public/aml-policy',
      },
      {
        components: {
          default: () =>
            import('~/pages/public/verification-levels-explained.vue'),

          'mobile-prepend-body': () => import('~/components/menu-legal-mobile'),
          sidebar: () => import('~/components/menu-legal'),
        },

        meta: { title: 'Legal' },
        name: 'public-verification-levels-explained',
        path: '/public/verification-levels-explained',
      },
    ],

    component: () => import('~/layouts/default.vue'),
    path: '/',
  },
];
