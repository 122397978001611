import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Отключение 2FA
 *
 * @param input.code {string} Код из приложения Google
 */
export const disable2fa = gql`
  query disable2fa($input: Any) {
    disable2fa(input: $input)
      @rest(
        path: "/spa/settings/2fa/disable"
        type: "Disable2Fa"
        method: "POST"
      ) {
      data
    }
  }
`;
