
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ACCOUNT_TYPES_FOR_UPGRADE } from '~/consts';

/**
 * Ссылка на апгрейд аккаунта
 */
@Component<AccountSummaryUpgradeLink>({})
export default class AccountSummaryUpgradeLink extends Vue {
  /**
   * Доступный для апгрейда тип аккаунта
   */
  @Prop({
    required: true,
    type: String,

    validator(value) {
      return ACCOUNT_TYPES_FOR_UPGRADE.includes(value);
    },
  })
  type!: typeof ACCOUNT_TYPES_FOR_UPGRADE[number];
}
