/* eslint-disable import/no-extraneous-dependencies */
import AlfaUiKit from '@alfa/ui-kit';
import { mqConfig, vuetifyConfig } from '@alfa/ui-kit/ui/configs';
import Vue from 'vue';
import VueMq from 'vue-mq';
import type { UserVuetifyPreset } from 'vuetify';
import Vuetify from 'vuetify/lib';

Vue.use(AlfaUiKit);

Vue.use(VueMq, mqConfig);

Vue.use(Vuetify);

export default new Vuetify(vuetifyConfig as Partial<UserVuetifyPreset>);
