import Vue from 'vue';

import * as utils from '~/utils';

declare module 'vue/types/vue' {
  interface Vue {
    //
    /**
     * Набор утилит
     */
    $utils: typeof utils;
  }
}

Vue.prototype.$utils = utils;
