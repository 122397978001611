import createPersistedState from 'vuex-persistedstate';

/**
 * Плагин для синхронизации состояния приложения с localStorage
 */
const persistedState = createPersistedState({
  paths: [
    'emailVerification',
    'formsData',
    // eslint-disable-next-line no-secrets/no-secrets
    'layout.isSignUpSliderVisibleOnSmallScreens',
  ],
});

export default persistedState;
