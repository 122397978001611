
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AccountServices from '~/components/account-services';
import AccountSummary from '~/components/account-summary';
import PersonalDetails from '~/components/personal-details';
import RecentActivity from '~/components/recent-activity';
import { UseLaravelData } from '~/mixins';

const FormsData = namespace('formsData');

/**
 * Страница информации о пользователе
 */
@Component<Page>({
  components: {
    AccountServices,
    AccountSummary,
    PersonalDetails,
    RecentActivity,
  },

  metaInfo: {
    title: 'My profile',
  },

  mounted() {
    this.clearFormsData();
  },
})
export default class Page extends Mixins(UseLaravelData) {
  @FormsData.Action('clearFormsData')
  readonly clearFormsData;
}
