/* eslint-disable sonarjs/no-duplicate-string */
import type { RouteConfig } from 'vue-router';

import { defaultLayoutRoutes } from './default-layout.routes';
import { errorLayoutRoutes } from './error-layout.routes';
import { loginLayoutRoutes } from './login-layout.routes';
import { signUpLayoutRoutes } from './sign-up-layout.routes';

const routes: RouteConfig[] = [
  ...defaultLayoutRoutes,
  ...errorLayoutRoutes,
  ...loginLayoutRoutes,
  ...signUpLayoutRoutes,
];

if (process.env.VUE_APP_ENV === 'local') {
  routes.push({
    children: [
      {
        component: () => import('~/pages/test/index.vue'),
        name: 'test',
        path: '',
      },
    ],

    component: () => import('~/layouts/default.vue'),
    path: '/test',
  });
}

routes.push({
  children: [
    {
      component: () => import('~/pages/error/404.vue'),
      path: '',
    },
  ],

  component: () => import('~/layouts/error.vue'),
  path: '*',
});

export { routes };
