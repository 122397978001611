import { gql } from '@alexlit/vue-apollo-kit';

/**
 * Удаление аккаунта
 */

export const deleteAccount = gql`
  query deleteAccount($input: Any) {
    deleteAccount(input: $input)
      @rest(
        path: "/spa/settings/deleteAccount"
        type: "DeleteAccount"
        method: "POST"
      ) {
      data
    }
  }
`;
