
import { Component, Mixins } from 'vue-property-decorator';

import { UseLaravelData } from '~/mixins';

/**
 * Страница с сообщением о том что страна пользователя не поддерживается
 */
@Component<CountryIsNotSupported>({
  metaInfo: {
    title: 'Your country is not supported yet',
  },

  mounted() {
    this.fromUrl = this.$utils.cookie.get('fromUrl');
  },
})
export default class CountryIsNotSupported extends Mixins(UseLaravelData) {
  /**
   * Адрес с которого пользователь пришел на регистрацию
   */
  fromUrl = '';
}
