
import { Component, Mixins } from 'vue-property-decorator';

import TextContent from '~/content/cookie-policy.md';
import { UseLaravelData } from '~/mixins';

/**
 * CookiePolicy
 */
@Component<CookiePolicy>({
  components: { TextContent },

  metaInfo: {
    title: 'Cookie Policy',
  },
})
export default class CookiePolicy extends Mixins(UseLaravelData) {}
