
import { Component, Mixins } from 'vue-property-decorator';

import FormLogin from '~/components/form-login';
import { UseLaravelData } from '~/mixins';

/**
 * Страница входа
 */
@Component<Login>({
  components: {
    FormLogin,
  },

  metaInfo: {
    title: 'Login',
  },
})
export default class Login extends Mixins(UseLaravelData) {}
