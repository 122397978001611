
import { Component, Mixins } from 'vue-property-decorator';

import { UseCopy } from '~/mixins';

/**
 * QR код
 */
@Component<ScanQrCode>({})
export default class ScanQrCode extends Mixins(UseCopy) {
  /**
   * Текст ошибки
   */
  errorText = '';

  /**
   * Формирует сообщение об ошибке в зависимости от ответа сервера
   *
   * @param message Ответ сервера
   */
  getErrorText(message: string): string {
    if (message.includes('400')) return 'You have already enabled 2FA';
    if (message.includes('422')) return 'Invalid code';

    return message;
  }
}
