
import { Component, Prop, Vue } from 'vue-property-decorator';

import FormDeleteAccount from '~/components/form-delete-account';

/**
 * Модалка с формой удаления аккаунта
 */
@Component<ModalDeleteAccount>({
  components: { FormDeleteAccount },
})
export default class ModalDeleteAccount extends Vue {
  /**
   * Видимость диалога
   */
  @Prop({ default: false, type: Boolean })
  readonly value!: boolean;

  /**
   * Если истина то аккаунт был удален
   */
  isDone = false;
}
