
import Cookies from 'js-cookie';
import { Component, Vue } from 'vue-property-decorator';

import { EUROPE_LOCATIONS } from '~/consts';

const COOKIE_KEY = 'is_accept_cookies';
const TRACE_HOST = 'https://alfacashier.com/cdn-cgi/trace';

/**
 * Баннер о куках
 */
@Component<CookieAlertBanner>({
  created() {
    this.checkCookie();
  },
})
export default class CookieAlertBanner extends Vue {
  /**
   * Отображение баннера
   */
  isShowBanner = false;

  /**
   * Проверяет куку
   */
  async checkCookie() {
    if (!Cookies.get(COOKIE_KEY)) {
      const clientCountry = await this.getClientCountry();

      const isUserFromEurope: boolean = EUROPE_LOCATIONS.includes(
        clientCountry as typeof EUROPE_LOCATIONS[number],
      );

      this.isShowBanner = isUserFromEurope;
    }
  }

  /**
   * Узнает страну клиента
   */
  async getClientCountry(): Promise<string> {
    const traceData = ((await this.$axios.get(TRACE_HOST)) || {
      data: '',
    }) as any;

    let data = traceData.data
      .replace(/[\n\r]+/g, '","')
      .replace(/=+/g, '":"');

    data = `{"${data.slice(0, data.lastIndexOf('","'))}"}`;

    return JSON.parse(data).loc || '';
  }

  /**
   * Устанавливает куку
   *
   * @param years Срок жизни куки, лет
   */
  setCookie(years: number): void {
    Cookies.set(COOKIE_KEY, true, { expires: 356 * years });
    this.isShowBanner = false;
  }
}
